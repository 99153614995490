import {
  ALLOCATION_ADD_FAIL,
  ALLOCATION_ADD_REQUEST,
  ALLOCATION_ADD_SUCCESS,
  ALLOCATION_ADD_RESET,
  ALLOCATION_ADD_BULK_REQUEST,
  ALLOCATION_ADD_BULK_SUCCESS,
  ALLOCATION_ADD_BULK_FAIL,
  ALLOCATION_ADD_BULK_RESET,
  ALLOCATION_LIST_REQUEST,
  ALLOCATION_LIST_SUCCESS,
  ALLOCATION_LIST_FAIL,
  ALLOCATION_LIST_RESET,
  ALLOCATION_DELETE_REQUEST,
  ALLOCATION_DELETE_SUCCESS,
  ALLOCATION_DELETE_FAIL,
  ALLOCATION_DELETE_RESET,
  ALLOCATION_EDIT_SUCCESS,
  ALLOCATION_EDIT_FAIL,
  ALLOCATION_EDIT_RESET,
  ALLOCATION_EDIT_REQUEST,
  ALLOCATION_LIST_REMOVE_ITEM,
  ALLOCATION_DETAILS_REQUEST,
  ALLOCATION_DETAILS_SUCCESS,
  ALLOCATION_DETAILS_FAIL,
  ALLOCATION_DETAILS_RESET,
} from '../constants/allocationConstants';

export const allocationCourseListReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_LIST_REQUEST:
      return { loading: true };
    case ALLOCATION_LIST_SUCCESS:
      return {
        loading: false,
        allocations: action.payload.studentCourse,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case ALLOCATION_LIST_REMOVE_ITEM:
      return {
        ...state,
        allocations: state.allocations.filter(
          (allocation) => allocation.student_course_id !== action.payload
        ),
      };
    case ALLOCATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ALLOCATION_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const allocationDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_DETAILS_REQUEST:
      return { loading: true };
    case ALLOCATION_DETAILS_SUCCESS:
      return {
        loading: false,
        allocation: action.payload,
      };
    case ALLOCATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ALLOCATION_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const allocationAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_ADD_REQUEST:
      return { loading: true };
    case ALLOCATION_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        allocation: action.payload,
      };
    case ALLOCATION_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case ALLOCATION_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const allocationAddBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_ADD_BULK_REQUEST:
      return { loading: true };
    case ALLOCATION_ADD_BULK_SUCCESS:
      return {
        loading: false,
        success: true,
        allocation: action.payload,
      };
    case ALLOCATION_ADD_BULK_FAIL:
      return { loading: false, success: false, error: action.payload };
    case ALLOCATION_ADD_BULK_RESET:
      return {};
    default:
      return state;
  }
};

export const allocationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_DELETE_REQUEST:
      return { loading: true };
    case ALLOCATION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ALLOCATION_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    case ALLOCATION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const allocationEditReducer = (state = {}, action) => {
  switch (action.type) {
    case ALLOCATION_EDIT_REQUEST:
      return { loading: true };
    case ALLOCATION_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
        allocation: action.payload,
      };
    case ALLOCATION_EDIT_FAIL:
      return { loading: false, success: false, error: action.payload };
    case ALLOCATION_EDIT_RESET:
      return {};
    default:
      return state;
  }
};
