import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { sendMultiMail } from '../../actions/mailActions';

export default function MailMultiPopup({ email, subject, message, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [mailAddress, setMailAddress] = useState([]);
  const [mailBody, setMailBody] = useState('');
  const [mailSubject, setMailSubject] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const mailSend = useSelector((state) => state.mailSend);
  const { loading: loadingMail, data } = mailSend;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEmail = (email) => {
    // window.alert(email);
    dispatch(
      sendMultiMail({
        emails: mailAddress,
        subject: mailSubject,
        body: mailBody,
      })
    );
  };

  useEffect(() => {
    setMailAddress(email);
    setMailBody(message);
    setMailSubject(subject);
  }, []);

  return (
    <>
      <Button
        size="medium"
        onClick={handleClickOpen}
        disabled={disabled}
        style={{ marginTop: '-6px' }}
      >
        Send Mail
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
              Type in Subject and Body of the mail and then click on Send button
              to send the email to the recipients. The email will be sent from
              noreply@ndub.edu.bd
            </DialogContentText> */}
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            size="small"
            fullWidth
            value={email}
            disabled
            onChange={(e) => setMailAddress(e.target.value)}
          />
          <TextField
            margin="dense"
            id="subject"
            label="Subject"
            type="text"
            size="small"
            fullWidth
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
          />
          <small
            style={{
              color: 'rgba(0,0,0,0.54)',
              fontWeight: 400,
              marginTop: '5px',
              marginBottom: '2px',
              display: 'block',
            }}
          >
            Body
          </small>
          <TextField
            id="subject"
            label="Message"
            margin="dense"
            size="small"
            multiline
            rows={10}
            fullWidth
            value={mailBody}
            onChange={(e) => setMailBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => onEmail(email)}
            disabled={loadingMail}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* {msg?.length > 0 && <Message />} */}
    </>
  );
}
