import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import Meta from '../../components/Meta';
import { listStudentCourses } from '../../actions/studentActions';
import { useEffect } from 'react';
import {
  EVALUATION_COURSE_DETAILS_RESET,
  EVALUATION_COURSE_DETAILS_SUCCESS,
} from '../../constants/evaluationConstants';
import Message from '../../components/Message';

const EvaluationStudentScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  if (userInfo.user.is_admin) {
    history.push('/evaluation/courselist');
  }

  const studentCourseList = useSelector((state) => state.studentCourseList);
  const { loading, error, courses } = studentCourseList;

  useEffect(() => {
    dispatch(listStudentCourses());
    dispatch({
      type: EVALUATION_COURSE_DETAILS_RESET,
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Evaluation'}
        description={'Evaluate a course taught by faculty member'}
        keywords={'evaluate course, evaluate faculty member performance'}
      />
      {!loading && !courses && (
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <p>No courses found</p>
          </Box>{' '}
        </Grid>
      )}
      {loading ? (
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>{' '}
        </Grid>
      ) : (
        courses?.map((course) => (
          <Grid item xs={12} sm={4} key={uuid()}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{ fontSize: 12 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {course.program_name}
                </Typography>
                <Typography variant="h5" component="div">
                  {course.course_name}
                </Typography>
                <Typography color="text.secondary">
                  Code: {course.course_code}, Credit: {course.course_credit}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {course.sem_name} {course.sem_year}
                </Typography>
                <Typography variant="body2">
                  Faculty: {course.teacher_name}
                </Typography>
              </CardContent>
              <CardActions>
                {course.hasevaluated ? (
                  <Button size="medium" disabled>
                    Evaluated
                  </Button>
                ) : (
                  <Link
                    to={`/evaluation/evaluate/${course.student_course_id}/${course.teacher_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      size="medium"
                      onClick={() =>
                        dispatch({
                          type: EVALUATION_COURSE_DETAILS_SUCCESS,
                          payload: course,
                        })
                      }
                    >
                      Evaluate
                    </Button>
                  </Link>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))
      )}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default EvaluationStudentScreen;
