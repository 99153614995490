import {
  TEACHER_LIST_REQUEST,
  TEACHER_LIST_SUCCESS,
  TEACHER_LIST_FAIL,
  TEACHER_LIST_RESET,
  TEACHER_LIST_REMOVE_ITEM,
  TEACHER_ADD_REQUEST,
  TEACHER_ADD_SUCCESS,
  TEACHER_ADD_FAIL,
  TEACHER_ADD_RESET,
  TEACHER_DELETE_REQUEST,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_RESET,
  TEACHER_DELETE_FAIL,
  TEACHER_DETAILS_REQUEST,
  TEACHER_DETAILS_SUCCESS,
  TEACHER_DETAILS_FAIL,
  TEACHER_DETAILS_RESET,
  TEACHER_EDIT_REQUEST,
  TEACHER_EDIT_SUCCESS,
  TEACHER_EDIT_FAIL,
  TEACHER_EDIT_RESET,
} from '../constants/teacherConstants';

export const teacherListReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_LIST_REQUEST:
      return { loading: true };
    case TEACHER_LIST_SUCCESS:
      return {
        loading: false,
        teachers: action.payload.teachers,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case TEACHER_LIST_REMOVE_ITEM:
      return {
        ...state,
        teachers: state.teachers.filter(
          (teacher) => teacher.teacher_id !== action.payload
        ),
      };
    case TEACHER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const teacherDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_DETAILS_REQUEST:
      return { loading: true };
    case TEACHER_DETAILS_SUCCESS:
      return {
        loading: false,
        teacher: action.payload,
      };
    case TEACHER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const teacherEditReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_EDIT_REQUEST:
      return { loading: true };
    case TEACHER_EDIT_SUCCESS:
      return {
        loading: false,
        teacher: action.payload,
        success: true,
      };
    case TEACHER_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case TEACHER_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const teacherAddReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_ADD_REQUEST:
      return { loading: true };
    case TEACHER_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        teacher: action.payload,
      };
    case TEACHER_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case TEACHER_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const teacherDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEACHER_DELETE_REQUEST:
      return { loading: true };
    case TEACHER_DELETE_SUCCESS:
      return {
        loading: false,
        teacher: action.payload,
      };
    case TEACHER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
