import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import {
  editSemester,
  getSemesterDetails,
} from '../../actions/semesterActions';

const SemesterEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    sem_name: '',
    sem_year: '',
    sem_start: null,
    sem_end: null,
    evaluation_open: '',
  });

  const semesterDetails = useSelector((state) => state.semesterDetails);
  const {
    loading: loadingDetails,
    success: successDetails,
    error: errorDetails,
    semester,
  } = semesterDetails;

  const semesterEdit = useSelector((state) => state.semesterEdit);
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = semesterEdit;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => {
        history.push('/semesters');
      }, 2000);
    } else {
      if (!semester || semester.sem_id != match.params.id) {
        dispatch(getSemesterDetails(match.params.id));
      } else {
        setValues({
          sem_name: values.sem_name ? values.sem_name : semester.sem_name,
          sem_year: values.sem_year ? values.sem_year : semester.sem_year,
          sem_start: values.sem_start ? values.sem_start : semester.sem_start,
          sem_end: values.sem_end ? values.sem_end : semester.sem_end,
          evaluation_open: values.evaluation_open
            ? values.evaluation_open
            : semester.evaluation_open,
        });
      }
    }
  }, [dispatch, successEdit, semester, match.params.id, history]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleYearChange = (newValue) => {
    setValues({ ...values, sem_year: new Date(newValue).getFullYear() });
  };

  const handleSemStartChange = (newValue) => {
    setValues({ ...values, sem_start: newValue });
  };

  const handleSemEndChange = (newValue) => {
    setValues({ ...values, sem_end: newValue });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editSemester(match.params.id, values));
  };

  return (
    <Container>
      <Meta
        title={'Edit semester'}
        description={
          'Edit a existing semester of the database of Notre Dame University Bangladesh'
        }
        keywords={'edit semester, update semester'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit semester</Typography>
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={3}>
                <TextField
                  select
                  value={values.sem_name}
                  label="Semester Name"
                  onChange={handleChange('sem_name')}
                  style={{ width: '100%' }}
                  size="small"
                >
                  <MenuItem value="SPRING">SPRING</MenuItem>
                  <MenuItem value="SUMMER">SUMMER</MenuItem>
                  <MenuItem value="FALL">FALL</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={['year']}
                    label="Year"
                    value={new Date(values.sem_year?.toString())}
                    onChange={(newValue) => handleYearChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="day"
                    views={['day', 'month', 'year']}
                    label="Start Date"
                    value={values.sem_start}
                    onChange={(newValue) => handleSemStartChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="day"
                    views={['day', 'month', 'year']}
                    label="End Date"
                    value={values.sem_end}
                    onChange={(newValue) => handleSemEndChange(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  value={values.evaluation_open}
                  label="Evaluation"
                  onChange={handleChange('evaluation_open')}
                  style={{ width: '100%' }}
                  size="small"
                >
                  {' '}
                  <MenuItem value={true}>Open</MenuItem>
                  <MenuItem value={false}>Closed</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Link to={'/semesters'}>
                  <Button
                    variant="outlined"
                    disableElevation
                    // size="large"
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  // size="large"
                  style={{ marginLeft: '10px' }}
                  // onClick={(e) => onSubmit(e)}
                  type="submit"
                >
                  Update{' '}
                  {loadingEdit && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default SemesterEditScreen;
