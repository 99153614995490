import {
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Pagination,
  FormControl,
  Select,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStudent, listStudents } from '../../actions/studentActions';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Message from '../../components/Message';
import StyledTableCell from '../../components/StyledTableCell';
import Meta from '../../components/Meta';
import { listBatches } from '../../actions/batchActions';
import { listSemesters } from '../../actions/semesterActions';
import {
  STUDENT_UPDATE_RESET,
  STUDENT_REGISTER_RESET,
} from '../../constants/studentConstants';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import debounce from 'lodash.debounce';

const StudentScreen = ({ history }) => {
  const dispatch = useDispatch();

  const studentList = useSelector((state) => state.studentList);
  const { loading, error, students, page, pages, total } = studentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = useState({
    stu_id: '',
    stu_name: '',
    program_id: '',
    dept_id: '',
    stu_email: '',
    stu_mobile_number: '',
    sortBy: 'batch_id',
    sortingOrder: 'DESC',
  });

  const [semester, setSemester] = useState(null);
  const [batch, setBatch] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const semesterList = useSelector((state) => state.semesterList);
  const { loading: loadingSemesters, semesters } = semesterList;

  const studentUpdate = useSelector((state) => state.studentUpdate);
  const { success: successUpdateStudent } = studentUpdate;

  const studentRegister = useSelector((state) => state.studentRegister);
  const { success: successStudentRegister } = studentRegister;

  useEffect(() => {
    if (successUpdateStudent) {
      dispatch({ type: STUDENT_UPDATE_RESET });
    }
    if (successStudentRegister) {
      dispatch({ type: STUDENT_REGISTER_RESET });
    }
    dispatch(
      listStudents(
        values.stu_id,
        values.stu_name,
        values.stu_email,
        values.stu_mobile_number,
        semester?.sem_id,
        batch?.batch_id,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
    if (!batches) {
      dispatch(listBatches());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
  }, []);

  useEffect(() => {
    dispatch(
      listStudents(
        values.stu_id,
        values.stu_name,
        values.stu_email,
        values.stu_mobile_number,
        semester?.sem_id,
        batch?.batch_id,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, [dispatch, semester, batch, pageNumber, pageSize]);

  const handleBatchChange = (batch) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setBatch(batch);
  };

  const handleSemesterChange = (semester) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setSemester(semester);
  };

  const loadData = (values) => {
    dispatch(
      listStudents(
        values.stu_id,
        values.stu_name,
        values.stu_email,
        values.stu_mobile_number,
        semester?.sem_id,
        batch?.batch_id,
        values.sortBy,
        values.sortingOrder,
        values.pageNumber,
        values.pageSize
      )
    );
  };

  const delayedQuery = useRef(
    debounce((values) => loadData(values), 1000)
  ).current;

  const handleChange = (prop) => (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setValues({ ...values, [prop]: event.target.value });
    delayedQuery({ ...values, [prop]: event.target.value });
  };

  const handleDelete = (stu_serial_id) => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      dispatch(deleteStudent(stu_serial_id));
    }
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setPageSize(event.target.value);
  };

  return (
    <Container>
      <Grid container spacing={1} mb={2}>
        <Meta
          title={'Students'}
          description={'Students of Notre Dame University Bangladesh'}
          keywords={'ndub students, add ndub students, ndub student'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/students/add')}
            disableElevation
          >
            <AddIcon /> &nbsp; Add Student
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.stu_name}
            onChange={handleChange('stu_name')}
            label="Name"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="number"
            value={values.stu_id}
            onChange={handleChange('stu_id')}
            label="Student ID"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SemesterSearchableDropdown
            semester={semester}
            setSemester={handleSemesterChange}
          />
          {loadingSemesters && <LinearProgress />}
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.stu_email}
            onChange={handleChange('stu_email')}
            label="Student Email"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.stu_mobile_number}
            onChange={handleChange('stu_mobile_number')}
            label="Mobile Number"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <BatchSearchableDropdown batch={batch} setBatch={handleBatchChange} />

          {loadingBatches && <LinearProgress />}
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="stu_name">Name</MenuItem>
            <MenuItem value="stu_id">Student ID</MenuItem>
            <MenuItem value="sem_id">Admission Semester</MenuItem>
            <MenuItem value="stu_email">Email</MenuItem>
            <MenuItem value="stu_mobile_number">Mobile</MenuItem>
            <MenuItem value="batch_id">Batch</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Pagination
              disabled={loading}
              count={pages ? pages : pagesFromAPI}
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
            <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
            <FormControl size="small">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
            {total ? (
              <small style={{ marginLeft: '20px' }}>
                {total} records found
              </small>
            ) : (
              <small style={{ marginLeft: '20px' }}>No records found</small>
            )}
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Student ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Phone</StyledTableCell>
              <StyledTableCell align="left">Ad Sem</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
              <StyledTableCell align="left">View/Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.map((student, index) => (
              <TableRow
                key={student.stu_serial_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {student.stu_id}
                </TableCell>
                <TableCell align="left">{student.stu_name}</TableCell>
                <TableCell align="left">{student.stu_email}</TableCell>
                <TableCell align="left">{student.stu_mobile_number}</TableCell>
                <TableCell align="left">
                  {student.sem_name} - {student.sem_year}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(student.stu_serial_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/students/edit/${student.stu_serial_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default StudentScreen;
