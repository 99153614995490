import { Button } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';
import { utils, writeFile } from 'xlsx';

const PerQuesCsvDownloader = ({
  details,
  overallResult,
  results,
  writtenResults,
}) => {
  const data = [];
  const writtenData = [];

  results.forEach((result) => {
    data.push([
      result.mcq_ques_en,
      result.strongly_agree,
      result.agree,
      result.neutral,
      result.disagree,
      result.strongly_disagree,
    ]);
  });

  writtenResults.forEach((result) => {
    writtenData.push([result.written_ques_en, result.written_ans.join()]);
  });

  const csvData = [
    ['Teacher Name', 'Semester', 'Batch', 'Course', 'Code', 'Credit'],
    [
      details.teacher_name,
      `${details.sem_name} ${details.sem_year}`,
      details.batch_name,
      details.course_name,
      details.course_code,
      details.course_credit,
    ],
    [],
    [
      'Overall Result',
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree',
    ],
    [
      `Enrolled: ${overallResult.total_enrolled}, Evaluated: ${overallResult.total_evaluated}`,
      overallResult.strongly_agree,
      overallResult.agree,
      overallResult.neutral,
      overallResult.disagree,
      overallResult.strongly_disagree,
    ],
    [],
    [
      'MCQ Questions',
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree',
    ],
    ...data,
    [],
    ['Written Questions', 'Written Answers'],
    ...writtenData,
  ];

  const currentTime = new Date();

  const handleOnExport = (e) => {
    e.preventDefault();
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(csvData);
    utils.book_append_sheet(wb, ws, `${details.course_code}`);
    // const max_width = csvData.reduce((w, r) => Math.max(w, r.name.length), 10);
    ws['!cols'] = [
      { wch: 70 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    writeFile(
      wb,
      `${details.teacher_name}_${details.sem_name}_${details.sem_year}_${
        details.batch_name
      }_${details.course_name}_${details.course_code}_${
        details.course_credit
      }_${currentTime.toLocaleTimeString()}.xlsx`
    );
  };

  return (
    <>
      <Button size="medium" onClick={(e) => handleOnExport(e)}>
        Download XLSX
      </Button>
      <Button size="medium">
        <CSVLink
          data={csvData}
          filename={`${details.teacher_name}_${details.sem_name}_${
            details.sem_year
          }_${details.batch_name}_${details.course_name}_${
            details.course_code
          }_${details.course_credit}_${currentTime.toLocaleTimeString()}.csv`}
        >
          Download CSV
        </CSVLink>
      </Button>
    </>
  );
};

export default PerQuesCsvDownloader;
