import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

import logo from './logo.svg';
import './App.css';

// Screens import
import HomeScreen from './screens/HomeScreen';
import DashboardScreen from './screens/DashboardScreen';
import EvaluationScreen from './screens/Evaluation/EvaluationScreen';
import Evaluate from './screens/Evaluation/Evaluate';
import LoginScreen from './screens/LoginScreen';

import StudentAddScreen from './screens/Student/StudentAddScreen';
import StudentListScreen from './screens/Student/StudentListScreen';
import StudentEditScreen from './screens/Student/StudentEditScreen';

import TeacherAddScreen from './screens/Teacher/TeacherAddScreen';
import TeacherListScreen from './screens/Teacher/TeacherListScreen';
import TeacherEditScreen from './screens/Teacher/TeacherEditScreen';

import DepartmentListScreen from './screens/Department/DepartmentListScreen';
import DepartmentEditScreen from './screens/Department/DepartmentEditScreen';
import DepartmentAddScreen from './screens/Department/DepartmentAddScreen';

import ProgramListScreen from './screens/Program/ProgramListScreen';
import ProgramAddScreen from './screens/Program/ProgramAddScreen';
import ProgramEditScreen from './screens/Program/ProgramEditScreen';

import CourseListScreen from './screens/Course/CourseListScreen';
import CourseAddScreen from './screens/Course/CourseAddScreen';
import CourseEditScreen from './screens/Course/CourseEditScreen';
import BatchListScreen from './screens/Batch/BatchListScreen';
import BatchAddScreen from './screens/Batch/BatchAddScreen';

import { createTheme, ThemeProvider } from '@mui/material';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';

// Components Import
import Header from './components/Header';
import SideBar from './components/SideBar';
import RegisterScreen from './screens/RegisterScreen';
import RegisterVerifyScreen from './screens/RegisterVerifyScreen';
import PasswordResetEmail from './screens/Recovery/PasswordResetEmailScreen';
import PasswordResetEmailVerify from './screens/Recovery/PasswordResetEmailVerifyScreen';

// Routes Import
import PrivateRoute from './routing/PrivateRoute';
import AdminRoute from './routing/AdminRoute';
import setAuthToken from './utils/setAuthToken';
import FacultyListScreen from './screens/Faculty/FacultyListScreen';
import FacultyAddScreen from './screens/Faculty/FacultyAddScreen';
import FacultyEditScreen from './screens/Faculty/FacultyEditScreen';
import EvaluationTeacherResultScreen from './components/EvaluationPerQuesAnswer';
import EvaluationAdminScreen from './screens/Evaluation/EvaluationAdminScreen';
import EvaluationAdminTeacherResultScreen from './screens/Evaluation/EvaluationAdminTeacherResultScreen';
import EvaluationTeacherReportScreen from './screens/Evaluation/EvaluationTeacherReportScreen';
import BatchEditScreen from './screens/Batch/BatchEditScreen';
import SemesterListScreen from './screens/Semester/SemesterListScreen';
import SemesterAddScreen from './screens/Semester/SemesterAddScreen';
import SemesterEditScreen from './screens/Semester/SemesterEditScreen';
import CourseAllocationListScreen from './screens/CourseAllocation/CourseAllocationListScreen';
import CourseAllocationAddScreen from './screens/CourseAllocation/CourseAllocationAddScreen';
import CourseAllocationAddBulkScreen from './screens/CourseAllocation/CourseAllocationAddBulkScreen';
import PasswordResetScreen from './screens/Recovery/PasswordResetScreen';
import ProfileDetailsScreen from './screens/Profile/ProfileDetailsScreen';
import CourseAllocationEditScreen from './screens/CourseAllocation/CourseAllocationEditScreen';
import HelpScreen from './screens/HelpScreen';
import UserListScreen from './screens/User/UserListScreen';
import UserAddScreen from './screens/User/UserAddScreen';

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0C2340',
    },
    secondary: {
      main: '#00ACEE',
    },
    danger: {
      main: 'rgba(245,16,16,0.87)',
    },
    white: {
      main: '#FFF',
    },
    common: {
      red: 'red',
      beige: 'black',
      white: 'white',
    },
    // text: {
    //   primary: 'rgba(245,16,16,0.87)',
    // },
    // error: {
    //   main: '#68f436',
    // },
    // info: {
    //   main: '#fff506',
    // },
    // success: {
    //   main: '#ff2300',
    // },
    // link: {
    //   main: '#fff506',
    // },
  },
  typography: {
    fontFamily: '"Poppins", "Arial", sans-serif',
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

if (localStorage.userInfo) {
  setAuthToken(JSON.parse(localStorage.userInfo).token);
}

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading } = userLogin;

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <DrawerHeader /> */}
        <Route path="/help" exact component={HelpScreen} />
        <Route path="/login" exact component={LoginScreen} />
        <Route path="/register" exact component={RegisterScreen} />
        <Route path="/register/verify" exact component={RegisterVerifyScreen} />
        <Route
          path="/register/verify?"
          exact
          component={RegisterVerifyScreen}
        />
        <Route path="/reset" exact component={PasswordResetEmail} />
        <Route
          path="/reset/verify"
          exact
          component={PasswordResetEmailVerify}
        />
        <Route
          path="/reset/verify?"
          exact
          component={PasswordResetEmailVerify}
        />
        <Route path="/reset/confirm" exact component={PasswordResetScreen} />
        <Route path="/reset/confirm?" exact component={PasswordResetScreen} />
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {userInfo && (
            <Header
              drawerWidth={drawerWidth}
              handleDrawerToggle={handleDrawerToggle}
              theme={theme}
            />
          )}
          {userInfo && (
            <SideBar
              drawerWidth={drawerWidth}
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
            />
          )}

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              pt: 1,
              width: { xs: '100vw', sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <DrawerHeader />
            <PrivateRoute exact path="/" component={HomeScreen} />
            {/* <PrivateRoute exact path="/dashboard" component={DashboardScreen} /> */}
            <PrivateRoute
              exact
              path="/profile"
              component={ProfileDetailsScreen}
            />
            <PrivateRoute
              exact
              path="/evaluation/evaluate/:student_course_id/:teacher_id"
              component={Evaluate}
            />
            <PrivateRoute
              theme={theme}
              exact
              path="/evaluation"
              component={EvaluationScreen}
            />
            <PrivateRoute
              exact
              path="/evaluation/report"
              component={EvaluationTeacherReportScreen}
            />
            <PrivateRoute
              exact
              path="/evaluation/report?"
              component={EvaluationTeacherReportScreen}
            />

            <PrivateRoute
              exact
              path="/evaluation/teacher"
              component={EvaluationAdminTeacherResultScreen}
            />
            <PrivateRoute
              exact
              path="/evaluation/teacher?"
              component={EvaluationAdminTeacherResultScreen}
            />
            {/* <PrivateRoute
              exact
              path="/evaluation/teacher"
              component={EvaluationTeacherResultScreen}
            /> */}

            <AdminRoute exact path="/users" component={UserListScreen} />
            <AdminRoute exact path="/users/add" component={UserAddScreen} />
            <AdminRoute exact path="/students" component={StudentListScreen} />
            <AdminRoute
              exact
              path="/students/edit/:id"
              component={StudentEditScreen}
            />
            <AdminRoute
              exact
              path="/students/add"
              component={StudentAddScreen}
            />

            <AdminRoute exact path="/teachers" component={TeacherListScreen} />
            <AdminRoute
              exact
              path="/teachers/add"
              component={TeacherAddScreen}
            />
            <AdminRoute
              exact
              path="/teachers/edit/:id"
              component={TeacherEditScreen}
            />

            <AdminRoute exact path="/faculties" component={FacultyListScreen} />
            <AdminRoute
              exact
              path="/faculties/add"
              component={FacultyAddScreen}
            />
            <AdminRoute
              exact
              path="/faculties/edit/:id"
              component={FacultyEditScreen}
            />

            <AdminRoute
              exact
              path="/departments"
              component={DepartmentListScreen}
            />
            <AdminRoute
              exact
              path="/departments/edit"
              component={DepartmentEditScreen}
            />
            <AdminRoute
              exact
              path="/departments/edit?"
              component={DepartmentEditScreen}
            />
            <AdminRoute
              exact
              path="/departments/add"
              component={DepartmentAddScreen}
            />

            <AdminRoute exact path="/programs" component={ProgramListScreen} />
            <AdminRoute
              exact
              path="/programs/add"
              component={ProgramAddScreen}
            />
            <AdminRoute
              exact
              path="/programs/edit/:id"
              component={ProgramEditScreen}
            />

            <AdminRoute
              exact
              path="/allocations"
              component={CourseAllocationListScreen}
            />
            <AdminRoute
              exact
              path="/allocations/add"
              component={CourseAllocationAddScreen}
            />
            <AdminRoute
              exact
              path="/allocations/add/bulk"
              component={CourseAllocationAddBulkScreen}
            />
            <AdminRoute
              exact
              path="/allocations/edit/:id"
              component={CourseAllocationEditScreen}
            />

            <AdminRoute exact path="/courses" component={CourseListScreen} />
            <AdminRoute exact path="/courses/add" component={CourseAddScreen} />
            <AdminRoute
              exact
              path="/courses/edit/:id"
              component={CourseEditScreen}
            />

            <AdminRoute exact path="/batches" component={BatchListScreen} />
            <AdminRoute exact path="/batches/add" component={BatchAddScreen} />
            <AdminRoute
              exact
              path="/batches/edit/:id"
              component={BatchEditScreen}
            />

            <AdminRoute
              exact
              path="/semesters"
              component={SemesterListScreen}
            />
            <AdminRoute
              exact
              path="/semesters/add"
              component={SemesterAddScreen}
            />
            <AdminRoute
              exact
              path="/semesters/edit/:id"
              component={SemesterEditScreen}
            />
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
