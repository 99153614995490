import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledTableCell from '../../components/StyledTableCell';
import { Link } from 'react-router-dom';
import { deleteSemester, listSemesters } from '../../actions/semesterActions';
import Message from '../../components/Message';
import { format } from 'date-fns';
import {
  SEMESTER_ADD_RESET,
  SEMESTER_DETAILS_RESET,
  SEMESTER_EDIT_RESET,
} from '../../constants/semesterConstants';
import Meta from '../../components/Meta';
import { REMOVE_ALERT } from '../../constants/alertConstants';

const SemesterListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    sem_name: '',
    sem_year: '',
    evaluation_open: '',
    sortBy: 'created_at',
    sortingOrder: 'DESC',
  });

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const semesterList = useSelector((state) => state.semesterList);
  const {
    loading: loadingSemesters,
    semesters,
    error: errorSemesters,
  } = semesterList;

  const semesterEdit = useSelector((state) => state.semesterEdit);
  const { success: successEdit } = semesterEdit;
  const semesterAdd = useSelector((state) => state.semesterAdd);
  const { success: successAdd } = semesterAdd;

  useEffect(() => {
    if (successEdit) {
      dispatch({ type: SEMESTER_EDIT_RESET });
      dispatch({ type: SEMESTER_DETAILS_RESET });
    }
    if (successAdd) {
      dispatch({ type: SEMESTER_ADD_RESET });
    }

    if (msg) dispatch({ type: REMOVE_ALERT });

    if (!semesters) {
      dispatch(
        listSemesters(
          values.sem_name,
          values.sem_year,
          values.evaluation_open,
          values.sortBy,
          values.sortingOrder
        )
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      listSemesters(
        values.sem_name,
        values.sem_year,
        values.evaluation_open,
        values.sortBy,
        values.sortingOrder
      )
    );
  }, [dispatch, values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDelete = (sem_id) => {
    if (window.confirm('Are you sure you want to delete this semester?')) {
      dispatch(deleteSemester(sem_id));
    }
  };

  return (
    <Container>
      <Meta
        title={'Semester'}
        description={'Semesters available at Notre Dame University Bangladesh'}
        keywords={'ndub semesters, add ndub semesters, ndub semester'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            onClick={() => history.push('/semesters/add')}
            style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Semester
          </Button>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sem_name}
            label="Semester"
            onChange={handleChange('sem_name')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="SPRING">SPRING</MenuItem>
            <MenuItem value="SUMMER">SUMMER</MenuItem>
            <MenuItem value="FALL">FALL</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            type="text"
            value={values.sem_year}
            onChange={handleChange('sem_year')}
            label="Year"
            style={{ width: '100%' }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.evaluation_open}
            label="Evaluation"
            onChange={handleChange('evaluation_open')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value={true}>Open</MenuItem>
            <MenuItem value={false}>Closed</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="sem_name">Semester</MenuItem>
            <MenuItem value="sem_year">Year</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>SL</StyledTableCell>
                  <StyledTableCell>Semester</StyledTableCell>
                  <StyledTableCell>Year</StyledTableCell>
                  <StyledTableCell>Start</StyledTableCell>
                  <StyledTableCell>End</StyledTableCell>
                  <StyledTableCell align="left">Evaluation</StyledTableCell>
                  <StyledTableCell align="center">Delete</StyledTableCell>
                  <StyledTableCell align="left">Edit/View</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {semesters?.map((semester, index) => (
                  <TableRow
                    key={semester.semester_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {semester.sem_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {semester.sem_year}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {format(new Date(semester.sem_start), 'dd-MM-yyyy')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {format(new Date(semester.sem_end), 'dd-MM-yyyy')}
                    </TableCell>
                    <TableCell align="left">
                      {semester.evaluation_open ? 'Open' : 'Closed'}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(semester.sem_id)}
                      >
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/semesters/edit/${semester.sem_id}`}>
                        <IconButton size="small">
                          <ArrowForwardIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loadingSemesters && <LinearProgress />}
          </TableContainer>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default SemesterListScreen;
