import axios from 'axios';

import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_ADD_REQUEST,
  COURSE_ADD_SUCCESS,
  COURSE_ADD_FAIL,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_LIST_REMOVE_ITEM,
  COURSE_EDIT_FAIL,
  COURSE_EDIT_SUCCESS,
  COURSE_EDIT_REQUEST,
  COURSE_DELETE_FAIL,
  COURSE_ADD_BULK_REQUEST,
  COURSE_ADD_BULK_SUCCESS,
  COURSE_ADD_BULK_FAIL,
} from '../constants/courseConstants';
import { setAlert } from './alertActions';

// Get all courses
export const listCourses =
  (
    program_id = '',
    course_code = '',
    course_name = '',
    course_type = '',
    course_credit = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50000
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: COURSE_LIST_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/courses?program_id=${program_id}&course_code=${course_code}&course_name=${course_name}&course_type=${course_type}&course_credit=${course_credit}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: COURSE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COURSE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single course details
export const getCourseDetails = (course_id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/courses/${course_id}`, config);

    dispatch({
      type: COURSE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: COURSE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add new course
export const addCourse = (values) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_ADD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/courses`, values, config);

    dispatch({
      type: COURSE_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Course added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: COURSE_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const addBulkCourse = (values) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_ADD_BULK_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post('/api/courses/bulk', values, config);
    dispatch({
      type: COURSE_ADD_BULK_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(`${data.total_course} courses added`, 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: COURSE_ADD_BULK_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Edit single course details
export const editCourse = (course_id, values) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_EDIT_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(
      `/api/courses/${course_id}`,
      values,
      config
    );

    dispatch({
      type: COURSE_EDIT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Course edited', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: COURSE_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a course
export const deleteCourse = (course_id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/courses/${course_id}`);

    dispatch({
      type: COURSE_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: COURSE_LIST_REMOVE_ITEM,
      payload: course_id,
    });
    dispatch(setAlert('Course deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: COURSE_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
