export const EVALUATION_COURSE_LIST_REQUEST = 'EVALUATION_COURSE_LIST_REQUEST';
export const EVALUATION_COURSE_LIST_SUCCESS = 'EVALUATION_COURSE_LIST_SUCCESS';
export const EVALUATION_COURSE_LIST_FAIL = 'EVALUATION_COURSE_LIST_FAIL';
export const EVALUATION_COURSE_LIST_RESET = 'EVALUATION_COURSE_LIST_RESET';
export const EVALUATION_COURSE_LIST_REMOVE_ITEM =
  'EVALUATION_COURSE_LIST_REMOVE_ITEM';

export const EVALUATION_COURSE_LIST_TEACHER_REQUEST =
  'EVALUATION_COURSE_LIST_TEACHER_REQUEST';
export const EVALUATION_COURSE_LIST_TEACHER_SUCCESS =
  'EVALUATION_COURSE_LIST_TEACHER_SUCCESS';
export const EVALUATION_COURSE_LIST_TEACHER_FAIL =
  'EVALUATION_COURSE_LIST_TEACHER_FAIL';
export const EVALUATION_COURSE_LIST_TEACHER_RESET =
  'EVALUATION_COURSE_LIST_TEACHER_RESET';

export const EVALUATION_COURSE_DETAILS_REQUEST =
  'EVALUATION_COURSE_DETAILS_REQUEST';
export const EVALUATION_COURSE_DETAILS_SUCCESS =
  'EVALUATION_COURSE_DETAILS_SUCCESS';
export const EVALUATION_COURSE_DETAILS_FAIL = 'EVALUATION_COURSE_DETAILS_FAIL';
export const EVALUATION_COURSE_DETAILS_RESET =
  'EVALUATION_COURSE_DETAILS_RESET';

export const EVALUATION_COURSE_CALCULATE_REQUEST =
  'EVALUATION_COURSE_CALCULATE_REQUEST';
export const EVALUATION_COURSE_CALCULATE_SUCCESS =
  'EVALUATION_COURSE_CALCULATE_SUCCESS';
export const EVALUATION_COURSE_CALCULATE_FAIL =
  'EVALUATION_COURSE_CALCULATE_FAIL';
export const EVALUATION_COURSE_CALCULATE_RESET =
  'EVALUATION_COURSE_CALCULATE_RESET';

export const EVALUATION_OVERALL_RESULT_REQUEST =
  'EVALUATION_OVERALL_RESULT_REQUEST';
export const EVALUATION_OVERALL_RESULT_SUCCESS =
  'EVALUATION_OVERALL_RESULT_SUCCESS';
export const EVALUATION_OVERALL_RESULT_FAIL = 'EVALUATION_OVERALL_RESULT_FAIL';
export const EVALUATION_OVERALL_RESULT_RESET =
  'EVALUATION_OVERALL_RESULT_RESET';

export const EVALUATION_REPORT_TEACHER_REQUEST =
  'EVALUATION_REPORT_TEACHER_REQUEST';
export const EVALUATION_REPORT_TEACHER_SUCCESS =
  'EVALUATION_REPORT_TEACHER_SUCCESS';
export const EVALUATION_REPORT_TEACHER_FAIL = 'EVALUATION_REPORT_TEACHER_FAIL';
export const EVALUATION_REPORT_TEACHER_RESET =
  'EVALUATION_REPORT_TEACHER_RESET';

export const EVALUATION_OVERALL_RESULT_DETAILS_REQUEST =
  'EVALUATION_OVERALL_RESULT_DETAILS_REQUEST';
export const EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS =
  'EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS';
export const EVALUATION_OVERALL_RESULT_DETAILS_FAIL =
  'EVALUATION_OVERALL_RESULT_DETAILS_FAIL';
export const EVALUATION_OVERALL_RESULT_DETAILS_RESET =
  'EVALUATION_OVERALL_RESULT_DETAILS_RESET';

export const EVALUATION_PER_QUES_RESULT_REQUEST =
  'EVALUATION_PER_QUES_RESULT_REQUEST';
export const EVALUATION_PER_QUES_RESULT_SUCCESS =
  'EVALUATION_PER_QUES_RESULT_SUCCESS';
export const EVALUATION_PER_QUES_RESULT_FAIL =
  'EVALUATION_PER_QUES_RESULT_FAIL';
export const EVALUATION_PER_QUES_RESULT_RESET =
  'EVALUATION_PER_QUES_RESULT_RESET';

export const EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST =
  'EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST';
export const EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS =
  'EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS';
export const EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL =
  'EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL';
export const EVALUATION_PER_QUES_WRITTEN_RESULT_RESET =
  'EVALUATION_PER_QUES_WRITTEN_RESULT_RESET';

export const EVALUATION_RESULT_PUBLISH_REQUEST =
  'EVALUATION_RESULT_PUBLISH_REQUEST';
export const EVALUATION_RESULT_PUBLISH_SUCCESS =
  'EVALUATION_RESULT_PUBLISH_SUCCESS';
export const EVALUATION_RESULT_PUBLISH_FAIL = 'EVALUATION_RESULT_PUBLISH_FAIL';
export const EVALUATION_RESULT_PUBLISH_RESET =
  'EVALUATION_RESULT_PUBLISH_RESET';

export const EVALUATION_RESULT_MAIL_REQUEST = 'EVALUATION_RESULT_MAIL_REQUEST';
export const EVALUATION_RESULT_MAIL_SUCCESS = 'EVALUATION_RESULT_MAIL_SUCCESS';
export const EVALUATION_RESULT_MAIL_FAIL = 'EVALUATION_RESULT_MAIL_FAIL';
export const EVALUATION_RESULT_MAIL_RESET = 'EVALUATION_RESULT_MAIL_RESET';

export const EVALUATION_RESULT_PDF_REQUEST = 'EVALUATION_RESULT_PDF_REQUEST';
export const EVALUATION_RESULT_PDF_SUCCESS = 'EVALUATION_RESULT_PDF_SUCCESS';
export const EVALUATION_RESULT_PDF_FAIL = 'EVALUATION_RESULT_PDF_FAIL';
export const EVALUATION_RESULT_PDF_RESET = 'EVALUATION_RESULT_PDF_RESET';

export const EVALUATION_STUDENT_LIST_REQUEST =
  'EVALUATION_STUDENT_LIST_REQUEST';
export const EVALUATION_STUDENT_LIST_SUCCESS =
  'EVALUATION_STUDENT_LIST_SUCCESS';
export const EVALUATION_STUDENT_LIST_FAIL = 'EVALUATION_STUDENT_LIST_FAIL';
export const EVALUATION_STUDENT_LIST_RESET = 'EVALUATION_STUDENT_LIST_RESET';
export const EVALUATION_STUDENT_LIST_REMOVE_ITEM =
  'EVALUATION_STUDENT_LIST_REMOVE_ITEM';
