export const EVAL_MCQ_QUES_LIST_REQUEST = 'EVAL_MCQ_QUES_LIST_REQUEST';
export const EVAL_MCQ_QUES_LIST_SUCCESS = 'EVAL_MCQ_QUES_LIST_SUCCESS';
export const EVAL_MCQ_QUES_LIST_FAIL = 'EVAL_MCQ_QUES_LIST_FAIL';
export const EVAL_MCQ_QUES_LIST_RESET = 'EVAL_MCQ_QUES_LIST_RESET';
export const EVAL_MCQ_QUES_LIST_REMOVE_ITEM = 'EVAL_MCQ_QUES_LIST_REMOVE_ITEM';

export const EVAL_MCQ_QUES_DETAILS_REQUEST = 'EVAL_MCQ_QUES_DETAILS_REQUEST';
export const EVAL_MCQ_QUES_DETAILS_SUCCESS = 'EVAL_MCQ_QUES_DETAILS_SUCCESS';
export const EVAL_MCQ_QUES_DETAILS_FAIL = 'EVAL_MCQ_QUES_DETAILS_FAIL';

export const EVAL_MCQ_QUES_DELETE_REQUEST = 'EVAL_MCQ_QUES_DELETE_REQUEST';
export const EVAL_MCQ_QUES_DELETE_SUCCESS = 'EVAL_MCQ_QUES_DELETE_SUCCESS';
export const EVAL_MCQ_QUES_DELETE_FAIL = 'EVAL_MCQ_QUES_DELETE_FAIL';
export const EVAL_MCQ_QUES_DELETE_RESET = 'EVAL_MCQ_QUES_DELETE_RESET';

export const EVAL_MCQ_QUES_CREATE_REQUEST = 'EVAL_MCQ_QUES_CREATE_REQUEST';
export const EVAL_MCQ_QUES_CREATE_SUCCESS = 'EVAL_MCQ_QUES_CREATE_SUCCESS';
export const EVAL_MCQ_QUES_CREATE_FAIL = 'EVAL_MCQ_QUES_CREATE_FAIL';
export const EVAL_MCQ_QUES_CREATE_RESET = 'EVAL_MCQ_QUES_CREATE_RESET';

export const EVAL_MCQ_QUES_UPDATE_REQUEST = 'EVAL_MCQ_QUES_UPDATE_REQUEST';
export const EVAL_MCQ_QUES_UPDATE_SUCCESS = 'EVAL_MCQ_QUES_UPDATE_SUCCESS';
export const EVAL_MCQ_QUES_UPDATE_FAIL = 'EVAL_MCQ_QUES_UPDATE_FAIL';
export const EVAL_MCQ_QUES_UPDATE_RESET = 'EVAL_MCQ_QUES_UPDATE_RESET';
