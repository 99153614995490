import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  editBatch,
  getBatchDetails,
  listBatches,
} from '../../actions/batchActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';

const BatchEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    batch_name: '',
    dept_id: '',
    program_id: '',
  });

  const batchDetails = useSelector((state) => state.batchDetails);
  const {
    loading: loadingDetails,
    success: successDetails,
    error: errorDetails,
    batch,
  } = batchDetails;

  const batchEdit = useSelector((state) => state.batchEdit);
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = batchEdit;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => {
        history.push('/batches');
      }, 2000);
    } else {
      if (!batch || batch.batch_id != match.params.id) {
        dispatch(getBatchDetails(match.params.id));
      } else {
        setValues({
          batch_name: values.batch_name ? values.batch_name : batch.batch_name,
          program_id: values.program_id ? values.program_id : batch.program_id,
          dept_id: values.dept_id ? values.dept_id : batch.dept_id,
        });
      }

      if (!programs) {
        dispatch(listPrograms(values.dept_id));
      }
      if (!departments) {
        dispatch(listDepartments());
      }
    }
  }, [
    dispatch,
    successEdit,
    batch,
    match.params.id,
    history,
    programs,
    departments,
  ]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    console.log('Dept ID: ', event.target.value);
    setValues({ ...values, dept_id: event.target.value });
    dispatch(listPrograms(event.target.value));
  };

  const handleProgramChange = (event) => {
    setValues({ ...values, program_id: event.target.value });
    dispatch(listBatches(event.target.value));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editBatch(match.params.id, values));
  };

  return (
    <Container>
      <Meta
        title={'Edit batch'}
        description={
          'Edit a existing batch of the database of Notre Dame University Bangladesh'
        }
        keywords={'edit batch, update batch'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit batch</Typography>
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12} sm={8}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-15px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={8}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  type="text"
                  value={values.batch_name}
                  onChange={handleChange('batch_name')}
                  label="Batch Name"
                  style={{ width: '100%' }}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  value={values.dept_id}
                  label="Department"
                  onChange={(event) => handleDepartmentChange(event)}
                  style={{ width: '100%' }}
                  disabled={loadingDepartments}
                  size="small"
                >
                  {departments?.map((department) => (
                    <MenuItem
                      value={department.dept_id}
                      key={department.dept_id}
                    >
                      {department.dept_name}
                    </MenuItem>
                  ))}
                </TextField>
                {loadingDepartments && <LinearProgress />}
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  value={values.program_id}
                  label="Program"
                  onChange={(event) => handleProgramChange(event)}
                  style={{ width: '100%' }}
                  disabled={loadingPrograms}
                  size="small"
                >
                  {programs?.map((program) => (
                    <MenuItem
                      value={program.program_id}
                      key={program.program_id}
                    >
                      {program.program_name}
                    </MenuItem>
                  ))}
                </TextField>
                {loadingPrograms && <LinearProgress />}
              </Grid>

              <Grid item xs={12}>
                <Link to={'/batches'}>
                  <Button variant="outlined" disableElevation>
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  style={{ marginLeft: '10px' }}
                  // onClick={(e) => onSubmit(e)}
                  type="submit"
                >
                  Update{' '}
                  {loadingEdit && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default BatchEditScreen;
