import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerBulkStudent,
  registerStudent,
} from '../../actions/studentActions';
import { listBatches } from '../../actions/batchActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';
import { listSemesters } from '../../actions/semesterActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { Link } from 'react-router-dom';

import CsvReader from '../../components/CsvReader';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import StudentBulkTable from './StudentBulkTable';

import { v4 as uuid } from 'uuid';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';

const StudentAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    stu_id: '',
    stu_name: '',
    batch_id: '',
    sem_id: '',
    stu_email: '',
    stu_mobile_number: '',
  });

  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [bulkInput, setBulkInput] = useState('single');
  const [batch, setBatch] = useState(null);
  const [semester, setSemester] = useState(null);

  const studentRegister = useSelector((state) => state.studentRegister);
  const { loading, success: successStudentRegister, student } = studentRegister;

  const studentBulkRegister = useSelector((state) => state.studentBulkRegister);
  const { loading: loadingBulk, students } = studentBulkRegister;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    dispatch(listBatches());
    dispatch(listSemesters());
    dispatch(listPrograms());
    dispatch(listDepartments());
  }, []);

  useEffect(() => {
    if (successStudentRegister) {
      setTimeout(() => {
        history.push('/students');
      }, 2000);
    }
  }, [successStudentRegister]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      registerStudent({
        ...values,
        batch_id: batch?.batch_id,
        sem_id: semester?.sem_id,
      })
    );
  };

  const handleBulkChange = (event) => {
    setBulkInput(event.target.value);
  };

  const handleChangeBulk = (e, id) => {
    const newBulkArray = csvArray.map((csv) => {
      if (id === csv.id) {
        e.target.value === null
          ? (csv[e.target.value] = '')
          : (csv[e.target.name] = e.target.value);
      }
      return csv;
    });
    setCsvArray(newBulkArray);
  };

  const handleAddBulkRow = (id) => {
    setCsvArray([
      ...csvArray,
      {
        id: id,
        stu_id: '',
        stu_name: '',
        stu_email: '',
        stu_mobile_number: '',
      },
    ]);
  };

  const handleRemoveBulkRow = (id) => {
    let values = [...csvArray];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setCsvArray([...values]);
  };

  const onBulkSubmit = (e) => {
    e.preventDefault();

    let validation = false;

    csvArray.forEach((item) => {
      if (
        item.stu_id === '' ||
        item.stu_name === '' ||
        item.stu_email === '' ||
        item.stu_mobile_number === ''
      ) {
        validation = true;
      }
    });

    if (validation) {
      window.alert('Please fill all the fields');
    } else
      dispatch(
        registerBulkStudent({
          sem_id: semester?.sem_id,
          batch_id: batch?.batch_id,
          student_info: csvArray,
        })
      );
  };

  return (
    <Container>
      <Meta
        title={'Add Student'}
        description={
          'Add new student to the student database of Notre Dame University Bangladesh'
        }
        keywords={'add new student, register student'}
      />{' '}
      <FormControl>
        <FormLabel>Entry Type</FormLabel>
        <RadioGroup row value={bulkInput} onChange={handleBulkChange}>
          <FormControlLabel value="single" control={<Radio />} label="Single" />
          <FormControlLabel value="bulk" control={<Radio />} label="Bulk" />
        </RadioGroup>
      </FormControl>
      <Divider />
      {bulkInput === 'bulk' ? (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <a href="/csv-templates/studentBulk.csv" download>
                <Button
                  variant="outlined"
                  size="small"
                  disableElevation
                  endIcon={<SaveAltIcon />}
                >
                  CSV
                </Button>
              </a>
              <CsvReader
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                csvArray={csvArray}
                setCsvArray={setCsvArray}
              />
            </div>
          </Grid>

          <br />

          {csvArray.length > 0 && (
            <>
              <Grid item xs={12} sm={3}>
                <BatchSearchableDropdown batch={batch} setBatch={setBatch} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SemesterSearchableDropdown
                  semester={semester}
                  setSemester={setSemester}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  style={{ width: '100%', height: '100%' }}
                  disableElevation
                  onClick={(e) => onBulkSubmit(e)}
                >
                  Add Bulk
                  {loadingBulk && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <StudentBulkTable
                  csvArray={csvArray}
                  handleChangeBulk={handleChangeBulk}
                  handleAddBulkRow={handleAddBulkRow}
                  handleRemoveBulkRow={handleRemoveBulkRow}
                />
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                value={values.stu_name}
                onChange={handleChange('stu_name')}
                label="Name"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={values.stu_id}
                onChange={handleChange('stu_id')}
                label="Student ID"
                style={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <BatchSearchableDropdown batch={batch} setBatch={setBatch} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SemesterSearchableDropdown
                semester={semester}
                setSemester={setSemester}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                value={values.stu_email}
                onChange={handleChange('stu_email')}
                label="Email"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={values.stu_mobile_number}
                onChange={handleChange('stu_mobile_number')}
                label="Mobile"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Link to={'/students'}>
                <Button
                  variant="outlined"
                  disableElevation
                  style={{ marginRight: '10px' }}
                >
                  Back
                </Button>
              </Link>
              <Button variant="contained" disableElevation type="submit">
                Add{' '}
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default StudentAddScreen;
