import { Button, IconButton, Input } from '@mui/material';
import { v4 as uuid } from 'uuid';
import ClearIcon from '@mui/icons-material/Clear';

export default function CsvReader({
  csvFile,
  setCsvFile,
  csvArray,
  setCsvArray,
}) {
  const processCSV = (str, delim = ',') => {
    let headers = str
      .replace('\r', '')
      .slice(0, str.indexOf('\n'))
      .split(delim);
    headers[headers.length - 1] = headers[headers.length - 1].replace('\n', '');

    console.log(JSON.stringify(str));
    let rows = str.slice(str.indexOf('\n') + 1).split('\n');

    rows.pop();

    const newArray = rows.map((row, i) => {
      row = row.replace('\r', '');
      const values = row.split(delim);

      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i].trim();
        return obj;
      }, {});
      eachObject.id = uuid();

      return eachObject;
    });

    setCsvArray(newArray);
  };

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;

      processCSV(text);
    };

    reader.readAsText(file);
  };

  return (
    <form id="csv-form">
      <Input
        type="file"
        accept=".csv"
        id="csvFile"
        onChange={(e) => {
          setCsvFile(e.target.files[0]);
        }}
      ></Input>
      <IconButton
        onClick={(e) => {
          document.getElementById('csvFile').value = null;
          setCsvFile('');
          setCsvArray([]);
        }}
      >
        <ClearIcon style={{ fontSize: '16px' }} />
      </IconButton>{' '}
      &nbsp;
      <Button
        variant="contained"
        size="small"
        disableElevation
        disabled={!csvFile || csvArray.length > 0}
        onClick={(e) => {
          e.preventDefault();
          if (csvFile) submit();
        }}
      >
        Upload
      </Button>
    </form>
  );
}
