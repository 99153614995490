import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDepartment,
  listDepartments,
} from '../../actions/departmentActions';
import Meta from '../../components/Meta';
import StyledTableCell from '../../components/StyledTableCell';

import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import {
  DEPARTMENT_ADD_RESET,
  DEPARTMENT_EDIT_RESET,
} from '../../constants/departmentConstants';
import Message from '../../components/Message';
import { deleteFaculty, listFaculties } from '../../actions/facultyActions';
import {
  FACULTY_ADD_RESET,
  FACULTY_EDIT_RESET,
} from '../../constants/facultyConstants';

const FacultyListScreen = ({ history }) => {
  const dispatch = useDispatch();
  const facultyList = useSelector((state) => state.facultyList);
  const { loading, error, faculties } = facultyList;

  const facultyAdd = useSelector((state) => state.facultyAdd);
  const { success: successAdd } = facultyAdd;

  const facultyEdit = useSelector((state) => state.facultyEdit);
  const { success: successEdit } = facultyEdit;

  const facultyDelete = useSelector((state) => state.facultyDelete);
  const { loading: loadingDelete } = facultyDelete;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successAdd) {
      dispatch({ type: FACULTY_ADD_RESET });
    }
    if (successEdit) {
      dispatch({ type: FACULTY_EDIT_RESET });
    }
    dispatch(listFaculties());
  }, [dispatch, successAdd, successEdit]);

  const handleDelete = (fac_id) => {
    if (window.confirm('Are you sure you want to delete this faculty?')) {
      dispatch(deleteFaculty(fac_id));
    }
  };

  return (
    <Container>
      <Grid container spacing={3} mb={2}>
        <Meta
          title={'Faculties'}
          description={'Faculties of Notre Dame University Bangladesh'}
          keywords={'ndub faculties, add ndub faculties, ndub faculty'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            onClick={() => history.push('/faculties/add')}
            style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Faculty
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Faculty Name</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
              <StyledTableCell align="center">View/Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faculties?.map((faculty, index) => (
              <TableRow
                key={faculty.fac_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {faculty.fac_name}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(faculty.fac_id)}
                    disabled={loadingDelete ? true : false}
                  >
                    <DeleteIcon
                      color={loadingDelete ? 'gray' : 'error'}
                      fontSize="small"
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/faculties/edit/${faculty.fac_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
        {loadingDelete && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default FacultyListScreen;
