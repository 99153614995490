import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerStudent } from '../../actions/studentActions';
import { listBatches } from '../../actions/batchActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { addBulkCourse, addCourse } from '../../actions/courseActions';
import CsvReader from '../../components/CsvReader';

import ProgramSearchableDropdown from '../../components/dropdowns/ProgramSearchableDropdown';
import CourseBulkTable from './CourseBulkTable';

const CourseAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    course_name: '',
    course_code: '',
    course_type: '',
    course_credit: '',
    program_id: '',
  });

  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [bulkInput, setBulkInput] = useState('single');
  const [program, setProgram] = useState(null);

  const courseAdd = useSelector((state) => state.courseAdd);
  const { loading, success, error, course } = courseAdd;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const courseAddBulk = useSelector((state) => state.courseAddBulk);
  const { loading: loadingBulk } = courseAddBulk;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/courses');
      }, 2000);
    } else {
      dispatch(listPrograms());
      dispatch(listDepartments());
    }
  }, [dispatch, history, success]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, department: event.target.value });
    dispatch(listPrograms(event.target.value));
  };

  const handleProgramChange = (event) => {
    setValues({ ...values, program_id: event.target.value });
    dispatch(listBatches(event.target.value));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addCourse(values));
  };

  const handleBulkChange = (event) => {
    setBulkInput(event.target.value);
  };

  const handleChangeBulk = (e, id) => {
    const newBulkArray = csvArray.map((csv) => {
      if (id === csv.id) {
        e.target.value === null
          ? (csv[e.target.value] = '')
          : (csv[e.target.name] = e.target.value);
      }
      return csv;
    });
    setCsvArray(newBulkArray);
  };

  const handleAddBulkRow = (id) => {
    setCsvArray([
      ...csvArray,
      {
        id: id,
        stu_id: '',
        stu_name: '',
        stu_email: '',
        stu_mobile_number: '',
      },
    ]);
  };

  const handleRemoveBulkRow = (id) => {
    let values = [...csvArray];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setCsvArray([...values]);
  };

  const onBulkSubmit = (e) => {
    e.preventDefault();

    let validation = false;

    csvArray.forEach((item) => {
      if (
        item.course_name === '' ||
        item.course_code === '' ||
        item.course_type === '' ||
        item.course_credit === ''
      ) {
        validation = true;
      }
    });

    if (validation) {
      window.alert('Please fill all the fields');
    } else
      dispatch(
        addBulkCourse({
          program_id: program?.program_id,
          courses: csvArray,
        })
      );
  };

  return (
    <Container>
      <Meta
        title={'Add Course'}
        description={
          'Add new course to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new course, register course'}
      />
      <FormControl>
        <FormLabel>Entry Type</FormLabel>
        <RadioGroup row value={bulkInput} onChange={handleBulkChange}>
          <FormControlLabel value="single" control={<Radio />} label="Single" />
          <FormControlLabel value="bulk" control={<Radio />} label="Bulk" />
        </RadioGroup>
      </FormControl>
      <Divider />
      {bulkInput === 'bulk' && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <a href="/csv-templates/courseBulk.csv" download>
                <Button
                  variant="outlined"
                  size="small"
                  disableElevation
                  endIcon={<SaveAltIcon />}
                >
                  CSV
                </Button>
              </a>
              <CsvReader
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                csvArray={csvArray}
                setCsvArray={setCsvArray}
              />
            </div>
          </Grid>
          {csvArray.length > 0 && (
            <>
              <Grid item xs={12} sm={3}>
                <ProgramSearchableDropdown
                  program={program}
                  setProgram={setProgram}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  style={{ width: '100%', height: '100%' }}
                  disableElevation
                  onClick={(e) => onBulkSubmit(e)}
                >
                  Add Bulk
                  {loadingBulk && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <CourseBulkTable
                  csvArray={csvArray}
                  handleChangeBulk={handleChangeBulk}
                  handleAddBulkRow={handleAddBulkRow}
                  handleRemoveBulkRow={handleRemoveBulkRow}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      <br />
      {bulkInput === 'single' && (
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                variant="outlined"
                type="text"
                value={values.course_name}
                onChange={handleChange('course_name')}
                label="Course Name"
                style={{ width: '100%' }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                required
                variant="outlined"
                type="text"
                value={values.course_code}
                onChange={handleChange('course_code')}
                label="Course Code"
                style={{ width: '100%' }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                required
                variant="outlined"
                type="number"
                value={values.course_credit}
                onChange={handleChange('course_credit')}
                label="Credit"
                style={{ width: '100%' }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                type="text"
                value={values.course_type}
                onChange={handleChange('course_type')}
                label="Course Type"
                style={{ width: '100%' }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                value={values.department}
                label="Department"
                onChange={(event) => handleDepartmentChange(event)}
                style={{ width: '100%' }}
                disabled={loadingDepartments}
                size="small"
              >
                {departments?.map((department) => (
                  <MenuItem value={department.dept_id} key={department.dept_id}>
                    {department.dept_name}
                  </MenuItem>
                ))}
              </TextField>
              {loadingDepartments && <LinearProgress />}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                value={values.program}
                label="Program"
                onChange={(event) => handleProgramChange(event)}
                style={{ width: '100%' }}
                disabled={loadingPrograms}
                size="small"
              >
                {programs?.map((program) => (
                  <MenuItem value={program.program_id} key={program.program_id}>
                    {program.program_name}
                  </MenuItem>
                ))}
              </TextField>
              {loadingPrograms && <LinearProgress />}
            </Grid>

            <Grid item xs={12}>
              <Link to={'/courses'}>
                <Button variant="outlined" disableElevation>
                  Back
                </Button>
              </Link>
              <Button
                variant="contained"
                disableElevation
                type="submit"
                style={{ marginLeft: '10px' }}
              >
                Add{' '}
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default CourseAddScreen;
