import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../Message';
import { sendMail } from '../../actions/mailActions';

export default function MailPopup({ email, subject, message, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [mailAddress, setMailAddress] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [mailSubject, setMailSubject] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const mailSend = useSelector((state) => state.mailSend);
  const { loading: loadingMail, data } = mailSend;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEmail = (email) => {
    // window.alert(email);
    dispatch(
      sendMail({ email: mailAddress, subject: mailSubject, body: mailBody })
    );
  };

  useEffect(() => {
    setMailAddress(email);
    setMailBody(message);
    setMailSubject(subject);
  }, []);

  return (
    <>
      <IconButton
        aria-label="mail"
        size="small"
        disabled={disabled}
        onClick={handleClickOpen}
      >
        <ForwardToInboxIcon
          color={disabled ? 'disabled' : 'primary'}
          fontSize="small"
        />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            size="small"
            fullWidth
            value={mailAddress}
            disabled
            onChange={(e) => setMailAddress(e.target.value)}
          />
          <TextField
            margin="dense"
            id="subject"
            label="Subject"
            type="text"
            size="small"
            fullWidth
            value={mailSubject}
            onChange={(e) => setMailSubject(e.target.value)}
          />
          <TextField
            id="subject"
            label="Message"
            margin="dense"
            size="small"
            multiline
            rows={10}
            fullWidth
            value={mailBody}
            onChange={(e) => setMailBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => onEmail(email)} disabled={loadingMail}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* {msg?.length > 0 && <Message />} */}
    </>
  );
}
