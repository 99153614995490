import {
  EVALUATION_COURSE_LIST_REQUEST,
  EVALUATION_COURSE_LIST_SUCCESS,
  EVALUATION_COURSE_LIST_FAIL,
  EVALUATION_COURSE_LIST_RESET,
  EVALUATION_COURSE_LIST_REMOVE_ITEM,
  EVALUATION_COURSE_DETAILS_REQUEST,
  EVALUATION_COURSE_DETAILS_SUCCESS,
  EVALUATION_COURSE_DETAILS_FAIL,
  EVALUATION_COURSE_DETAILS_RESET,
  EVALUATION_COURSE_CALCULATE_REQUEST,
  EVALUATION_COURSE_CALCULATE_SUCCESS,
  EVALUATION_COURSE_CALCULATE_FAIL,
  EVALUATION_COURSE_CALCULATE_RESET,
  EVALUATION_OVERALL_RESULT_REQUEST,
  EVALUATION_OVERALL_RESULT_SUCCESS,
  EVALUATION_OVERALL_RESULT_FAIL,
  EVALUATION_OVERALL_RESULT_RESET,
  EVALUATION_PER_QUES_RESULT_REQUEST,
  EVALUATION_PER_QUES_RESULT_SUCCESS,
  EVALUATION_PER_QUES_RESULT_RESET,
  EVALUATION_PER_QUES_RESULT_FAIL,
  EVALUATION_RESULT_PUBLISH_SUCCESS,
  EVALUATION_RESULT_PUBLISH_REQUEST,
  EVALUATION_RESULT_PUBLISH_FAIL,
  EVALUATION_RESULT_PUBLISH_RESET,
  EVALUATION_OVERALL_RESULT_DETAILS_REQUEST,
  EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS,
  EVALUATION_OVERALL_RESULT_DETAILS_FAIL,
  EVALUATION_OVERALL_RESULT_DETAILS_RESET,
  EVALUATION_COURSE_LIST_TEACHER_REQUEST,
  EVALUATION_COURSE_LIST_TEACHER_SUCCESS,
  EVALUATION_COURSE_LIST_TEACHER_FAIL,
  EVALUATION_COURSE_LIST_TEACHER_RESET,
  EVALUATION_REPORT_TEACHER_REQUEST,
  EVALUATION_REPORT_TEACHER_SUCCESS,
  EVALUATION_REPORT_TEACHER_FAIL,
  EVALUATION_REPORT_TEACHER_RESET,
  EVALUATION_RESULT_MAIL_REQUEST,
  EVALUATION_RESULT_MAIL_SUCCESS,
  EVALUATION_RESULT_MAIL_FAIL,
  EVALUATION_RESULT_MAIL_RESET,
  EVALUATION_RESULT_PDF_REQUEST,
  EVALUATION_RESULT_PDF_SUCCESS,
  EVALUATION_RESULT_PDF_FAIL,
  EVALUATION_RESULT_PDF_RESET,
  EVALUATION_STUDENT_LIST_REQUEST,
  EVALUATION_STUDENT_LIST_SUCCESS,
  EVALUATION_STUDENT_LIST_FAIL,
  EVALUATION_STUDENT_LIST_RESET,
  EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST,
  EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS,
  EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL,
  EVALUATION_PER_QUES_WRITTEN_RESULT_RESET,
} from '../constants/evaluationConstants';

export const evaluationCourseListReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_COURSE_LIST_REQUEST:
      return { loading: true };
    case EVALUATION_COURSE_LIST_SUCCESS:
      return {
        loading: false,
        evaluationCourses: action.payload.allocation,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case EVALUATION_COURSE_LIST_REMOVE_ITEM:
      return {
        ...state,
        evaluationCourses: state.evaluationCourses.filter(
          (evaluationCourse) =>
            evaluationCourse.EVALUATION_id !== action.payload
        ),
      };
    case EVALUATION_COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_COURSE_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationStudentListReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_STUDENT_LIST_REQUEST:
      return { loading: true };
    case EVALUATION_STUDENT_LIST_SUCCESS:
      return {
        loading: false,
        total: action.payload.total,
        hasevaluated: action.payload.hasevaluated,
        students: action.payload.students,
      };
    case EVALUATION_STUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_STUDENT_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationCourseListTeacherReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_COURSE_LIST_TEACHER_REQUEST:
      return { loading: true };
    case EVALUATION_COURSE_LIST_TEACHER_SUCCESS:
      return {
        loading: false,
        evaluationCourses: action.payload,
      };
    case EVALUATION_COURSE_LIST_TEACHER_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_COURSE_LIST_TEACHER_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationCourseReportTeacherReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_REPORT_TEACHER_REQUEST:
      return { loading: true };
    case EVALUATION_REPORT_TEACHER_SUCCESS:
      return {
        loading: false,
        report: action.payload,
      };
    case EVALUATION_REPORT_TEACHER_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_REPORT_TEACHER_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_COURSE_DETAILS_REQUEST:
      return { loading: true };
    case EVALUATION_COURSE_DETAILS_SUCCESS:
      return {
        loading: false,
        evaluationCourse: action.payload,
      };
    case EVALUATION_COURSE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_COURSE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationCourseCalculationReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_COURSE_CALCULATE_REQUEST:
      return { loading: true };
    case EVALUATION_COURSE_CALCULATE_SUCCESS:
      return {
        loading: false,
        evaluationResult: action.payload,
      };
    case EVALUATION_COURSE_CALCULATE_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_COURSE_CALCULATE_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationOverallResultReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_OVERALL_RESULT_REQUEST:
      return { loading: true };
    case EVALUATION_OVERALL_RESULT_SUCCESS:
      return {
        loading: false,
        evaluationOverallResult: action.payload,
      };
    case EVALUATION_OVERALL_RESULT_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_OVERALL_RESULT_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationOverallResultDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_OVERALL_RESULT_DETAILS_REQUEST:
      return { loading: true };
    case EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS:
      return {
        loading: false,
        evaluationResult: action.payload,
      };
    case EVALUATION_OVERALL_RESULT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_OVERALL_RESULT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationPerQuesAnsReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_PER_QUES_RESULT_REQUEST:
      return { loading: true };
    case EVALUATION_PER_QUES_RESULT_SUCCESS:
      return {
        loading: false,
        evaluationPerQuesAnsResult: action.payload,
      };
    case EVALUATION_PER_QUES_RESULT_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_PER_QUES_RESULT_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationPerQuesWrittenAnsReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST:
      return { loading: true };
    case EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS:
      return {
        loading: false,
        evaluationPerQuesWrittenAnsResult: action.payload,
      };
    case EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_PER_QUES_WRITTEN_RESULT_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationResultPublishReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_RESULT_PUBLISH_REQUEST:
      return { loading: true };
    case EVALUATION_RESULT_PUBLISH_SUCCESS:
      return {
        loading: false,
        published: action.payload,
      };
    case EVALUATION_RESULT_PUBLISH_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_RESULT_PUBLISH_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationResultMailReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_RESULT_MAIL_REQUEST:
      return { loading: true };
    case EVALUATION_RESULT_MAIL_SUCCESS:
      return {
        loading: false,
        mail: action.payload,
      };
    case EVALUATION_RESULT_MAIL_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_RESULT_MAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const evaluationResultPdfReducer = (state = {}, action) => {
  switch (action.type) {
    case EVALUATION_RESULT_PDF_REQUEST:
      return { loading: true };
    case EVALUATION_RESULT_PDF_SUCCESS:
      return {
        loading: false,
        pdf: action.payload,
      };
    case EVALUATION_RESULT_PDF_FAIL:
      return { loading: false, error: action.payload };
    case EVALUATION_RESULT_PDF_RESET:
      return {};
    default:
      return state;
  }
};
