import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ linkText, lastLink }) => {
  let arr1 = linkText.split('/');
  arr1.shift();
  let str = '',
    arr2 = [],
    arr3 = [];
  arr1.map((item, index) => {
    str = str + '/' + item;
    arr2.push(str);
    arr3.push(item.toUpperCase());
  });

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {arr2.map(
        (item, index) =>
          index < arr2.length - 1 && (
            <Link
              className={
                index === arr2.length - 1
                  ? 'breadcrumbs-link breadcrumbs-link-last'
                  : 'breadcrumbs-link'
              }
              to={item}
              key={item}
            >
              {arr3[index]}
            </Link>
          )
      )}
      <Typography color="text.primary">{lastLink}</Typography>
      {/* <Link underline="hover" color="inherit" to="/">
        MUI
      </Link>
      <Link
        underline="hover"
        color="inherit"
        to="/getting-started/installation/"
      >
        Core
      </Link>
      <Typography color="text.primary">Breadcrumbs</Typography> */}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
