import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { addSemester } from '../../actions/semesterActions';

const SemesterAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    sem_name: '',
    sem_year: new Date().getFullYear().toString(),
    sem_start: null,
    sem_end: null,
    evaluation_open: '',
  });

  const semesterAdd = useSelector((state) => state.semesterAdd);
  const { loading, success, error, semester } = semesterAdd;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/semesters');
      }, 2000);
    }
  }, [dispatch, history, success]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleYearChange = (newValue) => {
    setValues({
      ...values,
      sem_year: new Date(newValue).getFullYear().toString(),
    });
  };

  const handleSemStartChange = (newValue) => {
    setValues({ ...values, sem_start: newValue });
  };

  const handleSemEndChange = (newValue) => {
    setValues({ ...values, sem_end: newValue });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addSemester(values));
  };

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Add Semester'}
        description={
          'Add new semester to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new semester, register semester'}
      />
      {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
      <Grid item xs={12}>
        <Typography variant="h4">Add new semester</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          select
          value={values.sem_name}
          label="Semester Name"
          onChange={handleChange('sem_name')}
          style={{ width: '100%' }}
          size="small"
        >
          <MenuItem value="SPRING">SPRING</MenuItem>
          <MenuItem value="SUMMER">SUMMER</MenuItem>
          <MenuItem value="FALL">FALL</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={['year']}
            label="Year"
            value={new Date(values.sem_year?.toString())}
            onChange={(newValue) => handleYearChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} helperText={null} size="small" />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="day"
            views={['day', 'month', 'year']}
            label="Start Date"
            value={values.sem_start}
            onChange={(newValue) => handleSemStartChange(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="day"
            views={['day', 'month', 'year']}
            label="End Date"
            value={values.sem_end}
            onChange={(newValue) => handleSemEndChange(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          select
          value={values.evaluation_open}
          label="Evaluation"
          onChange={handleChange('evaluation_open')}
          style={{ width: '100%' }}
          size="small"
        >
          {' '}
          <MenuItem value={true}>Open</MenuItem>
          <MenuItem value={false}>Closed</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Link to={'/semesters'}>
          <Button variant="outlined" disableElevation type="submit">
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          style={{ marginLeft: '10px' }}
          onClick={(e) => onSubmit(e)}
        >
          Add{' '}
          {loading && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
      {/* </form> */}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default SemesterAddScreen;
