import {
  STUDENT_BULK_REGISTER_FAIL,
  STUDENT_BULK_REGISTER_REQUEST,
  STUDENT_BULK_REGISTER_RESET,
  STUDENT_BULK_REGISTER_SUCCESS,
  STUDENT_COURSE_LIST_FAIL,
  STUDENT_COURSE_LIST_REQUEST,
  STUDENT_COURSE_LIST_RESET,
  STUDENT_COURSE_LIST_SUCCESS,
  STUDENT_DELETE_FAIL,
  STUDENT_DELETE_REQUEST,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DETAILS_FAIL,
  STUDENT_DETAILS_REQUEST,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_LIST_FAIL,
  STUDENT_LIST_REMOVE_ITEM,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_RESET,
  STUDENT_LIST_SUCCESS,
  STUDENT_REGISTER_FAIL,
  STUDENT_REGISTER_REQUEST,
  STUDENT_REGISTER_RESET,
  STUDENT_REGISTER_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_RESET,
  STUDENT_UPDATE_SUCCESS,
} from '../constants/studentConstants';

export const studentListReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return { loading: true };
    case STUDENT_LIST_SUCCESS:
      return {
        loading: false,
        students: action.payload.students,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case STUDENT_LIST_REMOVE_ITEM:
      return {
        ...state,
        students: state.students.filter(
          (student) => student.stu_serial_id !== action.payload
        ),
      };
    case STUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const studentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_DETAILS_REQUEST:
      return { loading: true };
    case STUDENT_DETAILS_SUCCESS:
      return {
        loading: false,
        student: action.payload,
      };
    case STUDENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const studentCourseListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case STUDENT_COURSE_LIST_REQUEST:
      return { loading: true, courses: [] };
    case STUDENT_COURSE_LIST_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case STUDENT_COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_COURSE_LIST_RESET:
      return { courses: [] };
    default:
      return state;
  }
};

export const studentRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_REGISTER_REQUEST:
      return { loading: true };
    case STUDENT_REGISTER_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case STUDENT_REGISTER_FAIL:
      return { loading: false, success: false, error: action.payload };
    case STUDENT_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const studentBulkRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_BULK_REGISTER_REQUEST:
      return { loading: true };
    case STUDENT_BULK_REGISTER_SUCCESS:
      return { loading: false, students: action.payload };
    case STUDENT_BULK_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_BULK_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const studentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_UPDATE_REQUEST:
      return { loading: true };
    case STUDENT_UPDATE_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case STUDENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const studentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_DELETE_REQUEST:
      return { loading: true };
    case STUDENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case STUDENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
