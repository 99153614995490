import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  registerBulkStudent,
  registerStudent,
} from '../../actions/studentActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { Link } from 'react-router-dom';

import CsvReader from '../../components/CsvReader';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import StudentBulkTable from './StudentBulkTable';

import { v4 as uuid } from 'uuid';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import { createUser } from '../../actions/userActions';

const UserAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    name: '',
    user_email: '',
    password: '',
    confirmPassword: '',
    role: '',
    is_faculty: '',
    is_admin: '',
    is_superadmin: '',
  });

  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [bulkInput, setBulkInput] = useState('single');
  const [batch, setBatch] = useState(null);
  const [semester, setSemester] = useState(null);

  const userCreate = useSelector((state) => state.userCreate);
  const { loading, success: successUserCreate, user } = userCreate;

  const studentBulkRegister = useSelector((state) => state.studentBulkRegister);
  const { loading: loadingBulk, students } = studentBulkRegister;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successUserCreate) {
      setTimeout(() => {
        history.push('/users');
      }, 2000);
    }
  }, [successUserCreate]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (values.password !== values.confirmPassword) {
      window.alert("Passwords don't match");
    } else {
      dispatch(
        createUser(
          values.name,
          values.user_email,
          values.password,
          values.confirmPassword,
          values.role
        )
      );
    }
    // dispatch(
    //   registerStudent({
    //     ...values,
    //     batch_id: batch?.batch_id,
    //     sem_id: semester?.sem_id,
    //   })
    // );
  };

  const handleBulkChange = (event) => {
    setBulkInput(event.target.value);
  };

  const handleChangeBulk = (e, id) => {
    const newBulkArray = csvArray.map((csv) => {
      if (id === csv.id) {
        e.target.value === null
          ? (csv[e.target.value] = '')
          : (csv[e.target.name] = e.target.value);
      }
      return csv;
    });
    setCsvArray(newBulkArray);
  };

  const handleAddBulkRow = (id) => {
    setCsvArray([
      ...csvArray,
      {
        id: id,
        stu_id: '',
        stu_name: '',
        stu_email: '',
        stu_mobile_number: '',
      },
    ]);
  };

  const handleRemoveBulkRow = (id) => {
    let values = [...csvArray];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setCsvArray([...values]);
  };

  const onBulkSubmit = (e) => {
    e.preventDefault();

    let validation = false;

    csvArray.forEach((item) => {
      if (
        item.stu_id === '' ||
        item.stu_name === '' ||
        item.stu_email === '' ||
        item.stu_mobile_number === ''
      ) {
        validation = true;
      }
    });

    if (validation) {
      window.alert('Please fill all the fields');
    } else
      dispatch(
        registerBulkStudent({
          sem_id: semester?.sem_id,
          batch_id: batch?.batch_id,
          student_info: csvArray,
        })
      );
  };

  const handleRoleChange = (event) => {
    setValues({ ...values, role: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Meta
        title={'Add User'}
        description={
          'Add new user to the user database of Notre Dame University Bangladesh'
        }
        keywords={'add new user, register user'}
      />{' '}
      {/* <FormControl>
        <FormLabel>Entry Type</FormLabel>
        <RadioGroup row value={bulkInput} onChange={handleBulkChange}>
          <FormControlLabel value="single" control={<Radio />} label="Single" />
          <FormControlLabel value="bulk" control={<Radio />} label="Bulk" />
        </RadioGroup>
      </FormControl>
      <Divider /> */}
      {/* {bulkInput === 'bulk' ? (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <a href="/csv-templates/studentBulk.csv" download>
                <Button
                  variant="outlined"
                  size="small"
                  disableElevation
                  endIcon={<SaveAltIcon />}
                >
                  CSV
                </Button>
              </a>
              <CsvReader
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                csvArray={csvArray}
                setCsvArray={setCsvArray}
              />
            </div>
          </Grid>

          <br />

          {csvArray.length > 0 && (
            <>
              <Grid item xs={12} sm={3}>
                <BatchSearchableDropdown batch={batch} setBatch={setBatch} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SemesterSearchableDropdown
                  semester={semester}
                  setSemester={setSemester}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  style={{ width: '100%', height: '100%' }}
                  disableElevation
                  onClick={(e) => onBulkSubmit(e)}
                >
                  Add Bulk
                  {loadingBulk && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>

              {/* <Grid item xs={12}>
                <StudentBulkTable
                  csvArray={csvArray}
                  handleChangeBulk={handleChangeBulk}
                  handleAddBulkRow={handleAddBulkRow}
                  handleRemoveBulkRow={handleRemoveBulkRow}
                />
              </Grid> 
            </>
          )}
        </Grid>
      ) : ( */}
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Add new user</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              value={values.name}
              onChange={handleChange('name')}
              label="Name"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              value={values.user_email}
              onChange={handleChange('user_email')}
              label="Email"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              variant="outlined"
              style={{ width: '100%', marginBottom: '20px' }}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              variant="outlined"
              style={{ width: '100%', marginBottom: '20px' }}
              type={values.showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onChange={handleChange('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Confirm Password"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Role</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="role"
                value={values.role}
                onChange={handleRoleChange}
                row
              >
                <FormControlLabel
                  value="student"
                  control={<Radio />}
                  label="Student"
                />
                <FormControlLabel
                  value="faculty"
                  control={<Radio />}
                  label="Faculty"
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="examadmin"
                  control={<Radio />}
                  label="Exam Admin"
                />
                <FormControlLabel
                  value="superadmin"
                  control={<Radio />}
                  label="Super Admin"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Link to={'/users'}>
              <Button
                variant="outlined"
                disableElevation
                style={{ marginRight: '10px' }}
              >
                Back
              </Button>
            </Link>
            <Button variant="contained" disableElevation type="submit">
              Add{' '}
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* )} */}
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default UserAddScreen;
