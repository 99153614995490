import axios from 'axios';

import {
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_DETAILS_REQUEST,
  BATCH_DETAILS_SUCCESS,
  BATCH_DETAILS_FAIL,
  BATCH_EDIT_REQUEST,
  BATCH_EDIT_SUCCESS,
  BATCH_EDIT_FAIL,
  BATCH_ADD_REQUEST,
  BATCH_ADD_SUCCESS,
  BATCH_ADD_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_LIST_REMOVE_ITEM,
  BATCH_DELETE_FAIL,
} from '../constants/batchConstants';
import { setAlert } from './alertActions';

// Get all batch
export const listBatches =
  (program_id = '', batch_name = '', sortingOrder = '', sortBy = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BATCH_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/batch?batch_name=${batch_name}&program_id=${program_id}&sortingOrder=${sortingOrder}&sortBy=${sortBy}`,
        config
      );

      dispatch({
        type: BATCH_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BATCH_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single batch details
export const getBatchDetails = (batch_id) => async (dispatch) => {
  try {
    dispatch({ type: BATCH_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/batch/${batch_id}`, config);

    dispatch({
      type: BATCH_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BATCH_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Edit single batch details
export const editBatch = (batch_id, values) => async (dispatch) => {
  try {
    dispatch({ type: BATCH_EDIT_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(`/api/batch/${batch_id}`, values, config);

    dispatch({
      type: BATCH_EDIT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('batch edited', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: BATCH_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add new batch
export const addBatch = (values) => async (dispatch) => {
  try {
    dispatch({ type: BATCH_ADD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/batch`, values, config);

    dispatch({
      type: BATCH_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Batch added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: BATCH_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete a batch
export const deleteBatch = (batch_id) => async (dispatch) => {
  try {
    dispatch({ type: BATCH_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/batch/${batch_id}`);

    dispatch({
      type: BATCH_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: BATCH_LIST_REMOVE_ITEM,
      payload: batch_id,
    });
    dispatch(setAlert('batch deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: BATCH_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
