import React from 'react';
import { Grid, Button, TextField } from '@mui/material';

import FormLabel from '@mui/material/FormLabel';

import CircularProgress from '@mui/material/CircularProgress';

const EvaluateStep5 = ({
  evalWrittenQuestions,
  prevStep,
  updateWrittenAnswer,
  writtenAnswers,
  onSubmit,
  loadingSubmit,
}) => {
  return (
    <Grid container spacing={2} style={{ marginTop: '5px' }}>
      {evalWrittenQuestions.map((evalWrittenQuestion, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          key={evalWrittenQuestion.written_ques_id}
          mt={1}
        >
          <FormLabel style={{ marginBottom: '10px', display: 'block' }}>{`${
            index + 1
          }. ${evalWrittenQuestion.written_ques_en} (${
            evalWrittenQuestion.written_ques_bn
          })`}</FormLabel>
          <TextField
            required
            multiline
            rows={4}
            style={{ width: '100%' }}
            value={writtenAnswers[evalWrittenQuestion.written_ques_id] ?? ' '}
            onChange={(e) =>
              updateWrittenAnswer(evalWrittenQuestion.written_ques_id, e)
            }
          />
        </Grid>
      ))}

      <Grid item style={{ marginTop: '5px' }}>
        <Button
          variant="outlined"
          disableElevation
          size="large"
          type="submit"
          onClick={prevStep}
        >
          Back
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          disableElevation
          size="large"
          type="submit"
          onClick={onSubmit}
        >
          Submit{' '}
          {loadingSubmit && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EvaluateStep5;
