import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addBatch } from '../../actions/batchActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';

const BatchAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    batch_name: '',
    program_id: '',
    department_id: '',
  });

  const batchAdd = useSelector((state) => state.batchAdd);
  const { loading, success } = batchAdd;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/batches');
      }, 2000);
    } else {
      dispatch(listPrograms());
      dispatch(listDepartments());
    }
  }, [dispatch, history, success]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setValues({ ...values, department_id: event.target.value });
    dispatch(listPrograms(event.target.value));
  };

  const handleProgramChange = (event) => {
    setValues({ ...values, program_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addBatch(values));
  };

  return (
    <Grid container spacing={2}>
      <Meta
        title={'Add Batch'}
        description={
          'Add new batch to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new batch, register batch'}
      />
      {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
      <Grid item xs={12}>
        <Typography variant="h4">Add new batch</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          type="text"
          value={values.batch_name}
          onChange={handleChange('batch_name')}
          label="Batch Name"
          style={{ width: '100%' }}
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          value={values.department_id}
          label="Department"
          onChange={(event) => handleDepartmentChange(event)}
          style={{ width: '100%' }}
          disabled={loadingDepartments}
          size="small"
        >
          {departments?.map((department) => (
            <MenuItem value={department.dept_id} key={department.dept_id}>
              {department.dept_name}
            </MenuItem>
          ))}
        </TextField>
        {loadingDepartments && <LinearProgress />}
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          select
          value={values.program_id}
          label="Program"
          onChange={(event) => handleProgramChange(event)}
          style={{ width: '100%' }}
          disabled={loadingPrograms}
          size="small"
        >
          {programs?.map((program) => (
            <MenuItem value={program.program_id} key={program.program_id}>
              {program.program_name}
            </MenuItem>
          ))}
        </TextField>
        {loadingPrograms && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <Link to={'/batches'}>
          <Button variant="outlined" disableElevation>
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          // size="large"
          style={{ marginLeft: '10px' }}
          onClick={(e) => onSubmit(e)}
        >
          Add{' '}
          {loading && (
            <>
              &nbsp;
              <CircularProgress color="white" size={20} thickness={5} />
            </>
          )}
        </Button>
      </Grid>
      {/* </form> */}
      {msg?.length > 0 && <Message />}
    </Grid>
  );
};

export default BatchAddScreen;
