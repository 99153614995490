export const dictionary = {
  stu_email: 'Email',
  batch_name: 'Batch',
  stu_name: 'Name',
  batch_name: 'Batch Name',
  course_name: 'Course Name',
  course_code: 'Course Code',
  course_type: 'Course Type',
  course_credit: 'Course Credit',
  dept_name: 'Department',
  dept_url: 'Department URL',
  dept_code: 'Department Code',
  fac_name: 'Faculty Name',
  program_name: 'Program Name',
  program_type: 'Program Type',
  program_url: 'Program URL',
  program_credit: 'Program Credit',
  program_code: 'Program Code',
  sem_name: 'Semester Name',
  evaluation_open: 'Evaluation Open',
  sem_year: 'Semester Year',
  sem_start: 'Semester Start',
  sem_end: 'Semester End',
  evaluation_open: 'Evaluation Open',
  hasevaluated: 'Has Evaluated',
  stu_name: 'Student Name',
  stu_email: 'Student Email',
  stu_mobile_number: 'Student Mobile Number',
  mcq_ans: 'MCQ Answer',
  hasevaluated: 'Has Evaluated',
  evaluation_percentage: 'Evaluation Percentage',
  strongly_agree: 'Strongly Agree',
  agree: 'Agree',
  neutral: 'Neutral',
  disagree: 'Disagree',
  strongly_disagree: 'Strongly Disagree',
  mcq_ques_en: 'MCQ Question English',
  mcq_ques_bn: 'MCQ Question Bangla',
  written_ans: 'Written Answer',
  hasevaluated: 'Has Evaluated',
  written_ques_en: 'Written Question English',
  written_ques_bn: 'Written Question Bangla',
  teacher_name: 'Teacher Name',
  teacher_designation: 'Teacher Designation',
  teacher_email: 'Teacher Email',
  strongly_agree: 'Strongly Agree',
  agree: 'Agree',
  neutral: 'Neutral',
  disagree: 'Disagree',
  strongly_disagree: 'Strongly Disagree',
  total_enrolled: 'Total Enrolled',
  total_evaluated: 'Total Evaluated',
  published: 'Published',
  name: 'User Name',
  user_email: 'User Email',
  is_admin: 'Is Admin',
  is_superadmin: 'Is Super Admin',
  is_student: 'Is Student',
  is_faculty: 'Is Faculty',
  email_verified: 'Email Verified',
  verificationcode: 'Verification Code',
  stu_id: 'Student ID',
};
