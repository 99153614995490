import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import StyledTableCell from '../../components/StyledTableCell';
import { useDispatch, useSelector } from 'react-redux';
import { listEvaluationStudents } from '../../actions/evaluationActions';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { v4 as uuid } from 'uuid';
import CsvDownloader from '../../components/CsvDownloader';
import MailPopup from '../../components/popups/MailPopup';
import { sendMail } from '../../actions/mailActions';
import { sendSMS } from '../../actions/smsActions';
import SmsPopup from '../../components/popups/SmsPopup';
import SmsMultiPopup from '../../components/popups/SmsMultiPopup';
import MailMultiPopup from '../../components/popups/MailMultiPopup';

const EvaluationAdminStudentResultScreen = ({ evaluationResult }) => {
  const dispatch = useDispatch();

  const [hasevaluated, setHasevaluated] = useState('all');

  const evaluationStudentList = useSelector(
    (state) => state.evaluationStudentList
  );
  const { loading, error, students, total } = evaluationStudentList;

  const evaluationCourseDetails = useSelector(
    (state) => state.evaluationCourseDetails
  );
  const { evaluationCourse } = evaluationCourseDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { user } = userInfo;

  useEffect(() => {
    dispatch(
      listEvaluationStudents(
        evaluationCourse.sem_id,
        evaluationCourse.batch_id,
        evaluationCourse.teacher_id,
        evaluationCourse.course_id,
        hasevaluated
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      listEvaluationStudents(
        evaluationCourse.sem_id,
        evaluationCourse.batch_id,
        evaluationCourse.teacher_id,
        evaluationCourse.course_id,
        hasevaluated
      )
    );
  }, [hasevaluated]);

  const handleChange = (event) => {
    setHasevaluated(event.target.value);
  };

  const currentTime = new Date();

  return (
    <>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Students</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '-10px',
            }}
          >
            <FormControl size="small" sx={{ mb: 1 }}>
              {/* <FormLabel size="small">Evaluated</FormLabel> */}
              <RadioGroup
                row
                size="small"
                onChange={handleChange}
                value={hasevaluated}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio size="small" />}
                  label="All"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Evaluated"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="Not Evaluated"
                />
              </RadioGroup>
            </FormControl>
            {students && students.length > 0 && (
              <CsvDownloader
                data={students}
                columns={[
                  'stu_id',
                  'hasevaluated',
                  'stu_name',
                  'stu_email',
                  'batch_name',
                  'stu_mobile_number',
                  'course_name',
                  'course_code',
                  'sem_name',
                  'sem_year',
                  'teacher_name',
                  'teacher_email',
                ]}
                evaluationResult={evaluationResult}
                filename={`${students[0].teacher_name}_${
                  students[0].course_name
                }_${students[0].sem_name}_${
                  students[0].sem_year
                }_${currentTime.toLocaleTimeString()}.csv`}
              />
            )}
            {students && students.length > 0 && user.is_admin && (
              <SmsMultiPopup
                // phones={students.map((student) => student.stu_mobile_number)}
                phones={['01768392676', '01679399252']}
                message={`Dear Student,\n\nPlease complete your evaluation for the course: ${evaluationCourse.course_name.trim()} conducted by ${
                  evaluationCourse.teacher_name
                } by registering and logging into the NDUB Student Portal:\n\n${'https://portal.ndub.edu.bd'}\n\nCourse Code: ${
                  evaluationCourse.course_code
                }\nSemester: ${evaluationCourse.sem_name}(${
                  evaluationCourse.sem_year
                })\nBatch: ${evaluationCourse.batch_name}\n\nThank you.`}
                disabled={hasevaluated !== 'false' ? true : false}
              />
            )}
            {students && students.length > 0 && user.is_admin && (
              <MailMultiPopup
                email={students.map((obj) => obj.stu_email).join(', ')}
                subject={`Complete your evaluation for: ${evaluationCourse.course_name.trim()}(${
                  evaluationCourse.course_code
                }) - ${evaluationCourse.sem_name}(${
                  evaluationCourse.sem_year
                }) - ${evaluationCourse.batch_name} - ${
                  evaluationCourse.teacher_name
                }`}
                message={`Dear Student,\n\nPlease complete your evaluation for the course: ${evaluationCourse.course_name.trim()} conducted by ${
                  evaluationCourse.teacher_name
                } by registering and logging into the NDUB Student Portal:\n\n${'https://portal.ndub.edu.bd'}\n\nCourse Code: ${
                  evaluationCourse.course_code
                }\nSemester: ${evaluationCourse.sem_name}(${
                  evaluationCourse.sem_year
                })\nBatch: ${evaluationCourse.batch_name}\n\nThank you.`}
                disabled={hasevaluated !== 'false' ? true : false}
              />
            )}
          </div>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="course list table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>SL</StyledTableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Phone</StyledTableCell>
                  <StyledTableCell align="left">Evaluated</StyledTableCell>
                  <StyledTableCell align="left">Reminder</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((student, index) => (
                  <TableRow
                    key={uuid()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {student.stu_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {student.stu_name}
                    </TableCell>
                    <TableCell align="left">{student.stu_email}</TableCell>
                    <TableCell align="left">
                      {student.stu_mobile_number}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="delete" size="small" disabled>
                        {student.hasevaluated ? (
                          <CheckIcon color="primary" fontSize="small" />
                        ) : (
                          <CloseIcon color="error" fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <MailPopup
                        email={student.stu_email}
                        subject={`Complete your evaluation for: ${evaluationCourse.course_name.trim()}(${
                          evaluationCourse.course_code
                        }) - ${evaluationCourse.sem_name}(${
                          evaluationCourse.sem_year
                        }) - ${evaluationCourse.batch_name} - ${
                          evaluationCourse.teacher_name
                        }`}
                        message={`Dear ${
                          student.stu_name
                        },\n\nPlease complete your evaluation for the course: ${evaluationCourse.course_name.trim()} conducted by ${
                          evaluationCourse.teacher_name
                        } by registering and logging into the NDUB Student Portal:\n\n${'https://portal.ndub.edu.bd'}\n\nCourse Code: ${
                          evaluationCourse.course_code
                        }\nSemester: ${evaluationCourse.sem_name}(${
                          evaluationCourse.sem_year
                        })\nBatch: ${
                          evaluationCourse.batch_name
                        }\n\nThank you.`}
                        disabled={student.hasevaluated}
                      />

                      <SmsPopup
                        phone={student.stu_mobile_number}
                        message={`Dear ${
                          student.stu_name
                        },\n\nPlease complete your evaluation for the course: ${evaluationCourse.course_name.trim()} conducted by ${
                          evaluationCourse.teacher_name
                        } by registering and logging into the NDUB Student Portal:\n\n${'https://portal.ndub.edu.bd'}\n\nCourse Code: ${
                          evaluationCourse.course_code
                        }\nSemester: ${evaluationCourse.sem_name}(${
                          evaluationCourse.sem_year
                        })\nBatch: ${
                          evaluationCourse.batch_name
                        }\n\nThank you.`}
                        disabled={student.hasevaluated}
                      />
                    </TableCell>
                  </TableRow>
                ))}

                {!loading && !students && (
                  <TableRow>
                    <TableCell>No students found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EvaluationAdminStudentResultScreen;
