import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import { useDispatch, useSelector } from 'react-redux';
import { sendSMS } from '../../actions/smsActions';

export default function SmsPopup({ phone, message, disabled }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsBody, setSmsBody] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const smsSend = useSelector((state) => state.smsSend);
  const { loading: loadingSms, data } = smsSend;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSms = (phone) => {
    // window.alert(phone);
    dispatch(sendSMS({ phone: phoneNumber, message: smsBody }));
  };

  useEffect(() => {
    setPhoneNumber(phone);
    setSmsBody(message);
  }, []);

  return (
    <>
      <IconButton
        aria-label="mail"
        size="small"
        disabled={disabled}
        onClick={handleClickOpen}
      >
        <SmsIcon color={disabled ? 'disabled' : 'primary'} fontSize="small" />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send SMS</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Phone Number"
            type="phone"
            size="small"
            fullWidth
            value={phoneNumber}
            disabled
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            id="subject"
            label="Message"
            margin="dense"
            size="small"
            multiline
            rows={10}
            fullWidth
            value={smsBody}
            onChange={(e) => setSmsBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => onSms(phone)} disabled={loadingSms}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
