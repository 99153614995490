import axios from 'axios';
import {
  EVAL_ANS_SUBMIT_FAIL,
  EVAL_ANS_SUBMIT_REQUEST,
  EVAL_ANS_SUBMIT_SUCCESS,
} from '../constants/evalAnsConstants';
import { setAlert } from './alertActions';

export const submitEvaluationAnswers =
  (evaluationAnswers) => async (dispatch) => {
    try {
      dispatch({
        type: EVAL_ANS_SUBMIT_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'Application/json',
        },
      };
      console.log(evaluationAnswers);
      const { data } = await axios.post(
        '/api/evaluations/submit',
        evaluationAnswers,
        config
      );

      dispatch(setAlert(data, 'success'));

      dispatch({
        type: EVAL_ANS_SUBMIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVAL_ANS_SUBMIT_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };
