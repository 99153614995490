import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const ProgramSearchableDropdown = ({ program, setProgram }) => {
  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  // Set default props for programs dropdown
  const defaultProgramProps = {
    options: programs ? programs : [],
    getOptionLabel: (program) => program.program_name,
  };

  return (
    <>
      <Autocomplete
        {...defaultProgramProps}
        value={program}
        onChange={(event, newValue) => {
          setProgram(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Program"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingPrograms && <LinearProgress />}
    </>
  );
};

export default ProgramSearchableDropdown;
