import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../actions/courseActions';

const CourseSearchableDropdown = ({ course, setCourse, id }) => {
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courseList);
  const { loading: loadingCourses, courses } = courseList;

  // Set default props for courses dropdown
  const defaultCourseProps = {
    options: courses ? courses : [],
    getOptionLabel: (course) => `${course.course_name} (${course.course_code})`,
  };

  useEffect(() => {
    if (courses && courses.length <= 50) {
      dispatch(listCourses());
    } else if (!courses) {
      dispatch(listCourses());
    }
  }, []);

  return (
    <>
      <Autocomplete
        {...defaultCourseProps}
        autoComplete={false}
        value={course}
        onChange={(event, newValue) => {
          setCourse(newValue, id);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.course_id}>
              {option.course_name} ({option.course_code})
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Course"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingCourses && <LinearProgress />}
    </>
  );
};

export default CourseSearchableDropdown;
