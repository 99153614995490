import {
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NDUB_LOGO from '../../img/NDUB_Logo.png';

import Message from '../../components/Message';
// import { register } from '../actions/userActions';
import { useEffect } from 'react';
import { sendResetCode } from '../../actions/userActions';
import {
  USER_EMAIL_VERIFY_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
} from '../../constants/userConstants';

const PasswordResetEmail = ({ history }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userSendResetCode = useSelector((state) => state.userSendResetCode);
  const { loading, token } = userSendResetCode;

  useEffect(() => {
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
    dispatch({ type: USER_EMAIL_VERIFY_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
  }, []);

  useEffect(() => {
    if (token) {
      history.push(`/reset/verify?token=${token}`);
    }
  }, [history, token]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(sendResetCode(email.trim()));
  };

  return (
    <Container>
      <Grid
        container
        className="login-container"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">Reset your account's password</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header mb-20">Reset</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                variant="outlined"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="NDUB Email"
                style={{ width: '100%', marginBottom: '20px' }}
              />

              <i className="fas fa-user" style={{ color: '#000' }} />
              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Send Verification Mail
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Already have an account?{' '}
              <Link to="/login" style={{ textDecoration: 'underline' }}>
                Login
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default PasswordResetEmail;
