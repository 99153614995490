import {
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProgram, listPrograms } from '../../actions/programActions';
import StyledTableCell from '../../components/StyledTableCell';

import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import {
  PROGRAM_ADD_RESET,
  PROGRAM_EDIT_RESET,
} from '../../constants/programConstants';
import { listDepartments } from '../../actions/departmentActions';

const ProgramListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [deptId, setDeptId] = useState('');

  const programList = useSelector((state) => state.programList);
  const { loading, error, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDeparments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const programAdd = useSelector((state) => state.programAdd);
  const { success: successAdd } = programAdd;

  const programEdit = useSelector((state) => state.programEdit);
  const { success: successEdit } = programEdit;

  useEffect(() => {
    if (successAdd) {
      dispatch({ type: PROGRAM_ADD_RESET });
    }
    if (successEdit) {
      dispatch({ type: PROGRAM_EDIT_RESET });
    }
    if (!programs) {
      dispatch(listPrograms());
    }

    if (!departments) {
      dispatch(listDepartments());
    }
  }, [dispatch]);

  const handleDelete = (program_id) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      dispatch(deleteProgram(program_id));
    }
  };

  const handleDepartmentChange = (event) => {
    setDeptId(event.target.value);
    dispatch(listPrograms(event.target.value));
  };

  return (
    <Container>
      <Grid container spacing={3} mb={2} mt={-2}>
        <Meta
          title={'Programs'}
          description={'Programs of Notre Dame University Bangladesh'}
          keywords={'ndub programs, add ndub programs, ndub programs'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            onClick={() => history.push('/programs/add')}
            // size="small"
            style={{ width: '100%', height: '100%' }}
            disableElevation
          >
            <AddIcon /> &nbsp; Program
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            value={deptId}
            select
            label="Department"
            onChange={(event) => handleDepartmentChange(event)}
            style={{ width: '100%' }}
            disabled={loadingDepartments}
            size="small"
          >
            <MenuItem value=" ">All</MenuItem>
            {departments?.map((department) => (
              <MenuItem value={department.dept_id} key={department.dept_id}>
                {department.dept_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingDepartments && <LinearProgress />}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Program Name</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Credit</StyledTableCell>
              <StyledTableCell align="left">Department Name</StyledTableCell>
              <StyledTableCell align="left">URL</StyledTableCell>
              <StyledTableCell align="left">Delete</StyledTableCell>
              <StyledTableCell align="left">View/Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs?.map((program, index) => (
              <TableRow
                key={program.program_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {program.program_name}
                </TableCell>
                <TableCell align="left">{program.program_type}</TableCell>
                <TableCell align="left">{program.program_credit}</TableCell>
                <TableCell align="left">{program.dept_name}</TableCell>
                <TableCell align="left">
                  <a
                    href={program.program_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton aria-label="delete" size="small">
                      <OpenInNewIcon color="primary" fontSize="small" />
                    </IconButton>
                  </a>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(program.program_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/programs/edit/${program.program_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default ProgramListScreen;
