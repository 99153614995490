import axios from 'axios';
import {
  SMS_MULTI_SEND_FAIL,
  SMS_MULTI_SEND_REQUEST,
  SMS_MULTI_SEND_SUCCESS,
  SMS_SEND_FAIL,
  SMS_SEND_REQUEST,
  SMS_SEND_SUCCESS,
} from '../constants/smsConstants';
import { setAlert } from './alertActions';

// Add single sms
export const sendSMS = (values) => async (dispatch) => {
  try {
    dispatch({ type: SMS_SEND_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/sms`, values, config);

    dispatch({
      type: SMS_SEND_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data, 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SMS_SEND_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Add new multiple SMS
export const sendMultiSMS = (values) => async (dispatch) => {
  try {
    dispatch({ type: SMS_MULTI_SEND_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/sms/multi`, values, config);

    dispatch({
      type: SMS_MULTI_SEND_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('SMS sent successfully', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: SMS_MULTI_SEND_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
