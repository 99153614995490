import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Meta from '../../components/Meta';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_ALERT } from '../../constants/alertConstants';
import {
  deleteAllocation,
  listCourseAllocations,
} from '../../actions/allocationActions';
import { listBatches } from '../../actions/batchActions';
import { listSemesters } from '../../actions/semesterActions';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import { v4 as uuid } from 'uuid';
import StyledTableCell from '../../components/StyledTableCell';
import CourseSearchableDropdown from '../../components/dropdowns/CourseSearchableDropdown';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import TeacherSearchableDropdown from '../../components/dropdowns/TeacherSearchableDropdown';
import StudentSearchableDropdown from '../../components/dropdowns/StudentSearchableDropdown';
import Message from '../../components/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  ALLOCATION_ADD_BULK_RESET,
  ALLOCATION_ADD_RESET,
} from '../../constants/allocationConstants';

const CourseAllocationListScreen = () => {
  const dispatch = useDispatch();

  const [batch, setBatch] = useState(null);
  const [semester, setSemester] = useState(null);
  const [program, setProgram] = useState(null);
  const [course, setCourse] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [student, setStudent] = useState(null);
  const [sortBy, setSortBy] = useState('sem_id');
  const [sortingOrder, setSortingOrder] = useState('DESC');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);
  const [repeat_retake, setRepeat_retake] = useState('all');

  const allocationCourseList = useSelector(
    (state) => state.allocationCourseList
  );
  const { loading, allocations, page, pages, total } = allocationCourseList;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches } = batchList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters } = semesterList;

  const allocationAdd = useSelector((state) => state.allocationAdd);
  const { success: successAllocationAdd } = allocationAdd;

  const allocationAddBulk = useSelector((state) => state.allocationAddBulk);
  const { success: successAllocationAddBulk } = allocationAddBulk;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }
    if (successAllocationAdd) {
      dispatch({ type: ALLOCATION_ADD_RESET });
    }
    if (successAllocationAddBulk) {
      dispatch({ type: ALLOCATION_ADD_BULK_RESET });
    }
    // Fetch the courses on component mount
    if (!allocations) {
      dispatch(
        listCourseAllocations(
          semester?.sem_id,
          course?.course_id,
          batch?.batch_id,
          teacher?.teacher_id,
          student?.stu_serial_id,
          sortBy,
          sortingOrder,
          pageSize,
          pageNumber,
          repeat_retake
        )
      );
    }

    // Fetch the batches and programs and teachers on component mount
    if (!batches) {
      dispatch(listBatches());
    }

    if (!semesters) {
      dispatch(listSemesters());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the courses on component filter value update
  useEffect(() => {
    dispatch(
      listCourseAllocations(
        semester?.sem_id,
        course?.course_id,
        batch?.batch_id,
        teacher?.teacher_id,
        student?.stu_serial_id,
        sortBy,
        sortingOrder,
        pageNumber,
        pageSize,
        repeat_retake
      )
    );
  }, [
    dispatch,
    student,
    batch,
    semester,
    program,
    course,
    teacher,
    sortBy,
    sortingOrder,
    pageNumber,
    pageSize,
    pagesFromAPI,
    repeat_retake,
  ]);

  const handleStudentChange = (student) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setStudent(student);
  };

  const handleBatchChange = (batch) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setBatch(batch);
  };

  const handleSemesterChange = (semester) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setSemester(semester);
  };

  const handleCourseChange = (course) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setCourse(course);
  };

  const handleTeacherChange = (teacher) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setTeacher(teacher);
  };

  const handleDelete = (student_course_id) => {
    if (window.confirm('Are you sure you want to delete this allocation?')) {
      dispatch(deleteAllocation(student_course_id));
    }
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setPageSize(event.target.value);
  };

  return (
    <Container>
      <Meta
        title={'Course Allocations'}
        description={
          'Allocate courses teacher, semester, student, batch, course'
        }
        keywords={
          'ndub course allocations, add ndub course allocations, ndub course allocation'
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Link to={'/allocations/add'}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
              //   style={{ height: '200px' }}
              size="large"
            >
              Single Allocation
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link to={'/allocations/add/bulk'}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
              //   style={{ height: '200px' }}
              size="large"
            >
              Bulk Allocation
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12} sm={4}>
              <StudentSearchableDropdown
                student={student}
                setStudent={handleStudentChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SemesterSearchableDropdown
                semester={semester}
                setSemester={handleSemesterChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CourseSearchableDropdown
                course={course}
                setCourse={handleCourseChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BatchSearchableDropdown
                batch={batch}
                setBatch={handleBatchChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TeacherSearchableDropdown
                teacher={teacher}
                setTeacher={handleTeacherChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                select
                value={sortBy}
                label="Sort By"
                onChange={(e) => setSortBy(e.target.value)}
                style={{ width: '100%' }}
                size="small"
              >
                <MenuItem value="sem_id">Semester</MenuItem>
                <MenuItem value="student_serial_id">Student</MenuItem>
                <MenuItem value="batch_id">Batch</MenuItem>
                <MenuItem value="program_id">Program</MenuItem>
                <MenuItem value="course_id">Course</MenuItem>
                <MenuItem value="batch_id">Batch</MenuItem>
                <MenuItem value="teacher_id">Teacher</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                value={sortingOrder}
                label="Order"
                onChange={(e) => setSortingOrder(e.target.value)}
                style={{ width: '100%' }}
                size="small"
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                select
                value={repeat_retake}
                label="Student Type"
                onChange={(e) => setRepeat_retake(e.target.value)}
                style={{ width: '100%' }}
                size="small"
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'false'}>Regular</MenuItem>
                <MenuItem value={'true'}>Repeat or Retake</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // alignContent: 'center',
                  // justifyContent: 'center',
                }}
              >
                <Pagination
                  disabled={loading}
                  count={pages ? pages : pagesFromAPI}
                  page={pageNumber}
                  boundaryCount={2}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                  color="primary"
                />
                <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
                <FormControl size="small">
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    size="small"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                  </Select>
                </FormControl>
                {total ? (
                  <small style={{ marginLeft: '20px' }}>
                    {total} records found
                  </small>
                ) : (
                  <small style={{ marginLeft: '20px' }}>No records found</small>
                )}
              </div>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="course list table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>SL</StyledTableCell>
                  <StyledTableCell>Student</StyledTableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Course</StyledTableCell>
                  <StyledTableCell align="left">Teacher</StyledTableCell>
                  <StyledTableCell align="left">Code</StyledTableCell>
                  <StyledTableCell align="left">Semester</StyledTableCell>
                  <StyledTableCell align="left">Batch</StyledTableCell>
                  <StyledTableCell align="center">Delete</StyledTableCell>
                  <StyledTableCell align="center">View/Edit</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allocations?.map((course, index) => (
                  <TableRow
                    key={course.student_course_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {course.stu_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {course.stu_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {course.course_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {course.teacher_name}
                    </TableCell>
                    <TableCell align="left">{course.course_code}</TableCell>
                    <TableCell align="left">
                      {course.sem_name} - {course.sem_year}
                    </TableCell>
                    <TableCell align="left">{course.batch_name}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(course.student_course_id)}
                      >
                        <DeleteIcon color="error" fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        to={`/allocations/edit/${course.student_course_id}`}
                      >
                        <IconButton size="small">
                          <ArrowForwardIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

                {!loading && !allocations && (
                  <TableRow>
                    <TableCell>No course allocations found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          {msg?.length > 0 && <Message />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CourseAllocationListScreen;
