import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import Breadcrumb from '../../components/Breadcrumb';

import { listFaculties } from '../../actions/facultyActions';
import {
  editDepartment,
  getDepartmentDetails,
} from '../../actions/departmentActions';

import queryString from 'query-string';

const DepartmentAddScreen = ({ history, match, location }) => {
  const dispatch = useDispatch();

  if (location.search === '') {
    history.push('/departments');
  }

  const parsedQuery = queryString.parse(location.search);

  const [values, setValues] = useState({
    dept_name: '',
    dept_code: '',
    dept_url: '',
    fac_id: '',
  });

  const departmentDetails = useSelector((state) => state.departmentDetails);
  const { loading, success, error, department } = departmentDetails;

  const departmentEdit = useSelector((state) => state.departmentEdit);
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
    department: editedDepartment,
  } = departmentEdit;

  const facultyList = useSelector((state) => state.facultyList);
  const {
    loading: loadingFaculties,
    error: errorFaculties,
    faculties,
  } = facultyList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => {
        history.push('/departments');
      }, 2000);
    } else {
      if (!department || department.dept_id != parsedQuery.dept_id) {
        dispatch(getDepartmentDetails(parsedQuery.dept_id));
      } else {
        setValues({ ...department });
      }
      if (!faculties) {
        dispatch(listFaculties());
      }
    }
  }, [
    history,
    dispatch,
    faculties,
    department,
    parsedQuery.dept_id,
    successEdit,
  ]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleFacultyChange = (event) => {
    setValues({ ...values, fac_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editDepartment(parsedQuery.dept_id, values));
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb
            linkText={history.location.pathname}
            lastLink={values.dept_name}
          />
        </Grid>

        <Meta
          title={'Edit Department'}
          description={
            'Edit an existing department in the database of Notre Dame University Bangladesh'
          }
          keywords={'edit department, ndub department'}
        />
        {/* <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}> */}
        <Grid item xs={12}>
          <Typography variant="h4">Edit department</Typography>
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <Typography
              animation="wave"
              component="div"
              variant={'h3'}
              style={{ marginBottom: '-25px' }}
            >
              <Skeleton />
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              type="text"
              value={loading ? <Skeleton variant="text" /> : values.dept_name}
              onChange={handleChange('dept_name')}
              label="Department Name"
              style={{ width: '100%' }}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Typography
              animation="wave"
              component="div"
              variant={'h3'}
              style={{ marginBottom: '-25px' }}
            >
              <Skeleton />
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              type="text"
              value={values.dept_code}
              onChange={handleChange('dept_code')}
              label="Department Code"
              style={{ width: '100%' }}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Typography
              animation="wave"
              component="div"
              variant={'h3'}
              style={{ marginBottom: '-25px' }}
            >
              <Skeleton />
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              type="text"
              value={values.dept_url}
              onChange={handleChange('dept_url')}
              label="Department URL"
              style={{ width: '100%' }}
              size="small"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <Typography animation="wave" component="div" variant={'h3'}>
              <Skeleton />
            </Typography>
          ) : (
            <TextField
              select
              value={values.fac_id}
              label="Faculty"
              onChange={(event) => handleFacultyChange(event)}
              style={{ width: '100%' }}
              disabled={loadingFaculties}
              size="small"
            >
              {faculties?.map((faculty) => (
                <MenuItem value={faculty.fac_id} key={faculty.fac_id}>
                  {faculty.fac_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        {loading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        <Grid item xs={12}>
          <Link to={'/departments'}>
            <Button
              variant="outlined"
              disableElevation
              // size="large"
              type="submit"
            >
              Back
            </Button>
          </Link>
          <Button
            variant="contained"
            disableElevation
            // size="large"
            style={{ marginLeft: '10px' }}
            onClick={(e) => onSubmit(e)}
          >
            Submit{' '}
            {loadingEdit && (
              <>
                &nbsp;
                <CircularProgress color="white" size={20} thickness={5} />
              </>
            )}
          </Button>
        </Grid>
        {/* </form> */}
        {msg?.length > 0 && <Message />}
      </Grid>
    </Container>
  );
};

export default DepartmentAddScreen;
