import axios from 'axios';

import {
  PROGRAM_LIST_REQUEST,
  PROGRAM_LIST_SUCCESS,
  PROGRAM_LIST_FAIL,
  PROGRAM_DELETE_REQUEST,
  PROGRAM_DELETE_SUCCESS,
  PROGRAM_LIST_REMOVE_ITEM,
  PROGRAM_DELETE_FAIL,
  PROGRAM_ADD_FAIL,
  PROGRAM_ADD_SUCCESS,
  PROGRAM_ADD_REQUEST,
  PROGRAM_EDIT_FAIL,
  PROGRAM_EDIT_SUCCESS,
  PROGRAM_EDIT_REQUEST,
  PROGRAM_DETAILS_FAIL,
  PROGRAM_DETAILS_SUCCESS,
  PROGRAM_DETAILS_REQUEST,
} from '../constants/programConstants';
import { setAlert } from './alertActions';

// Get all programs
export const listPrograms =
  (dept_id = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PROGRAM_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      let data = await axios.get(`/api/programs?dept_id=${dept_id}`, config);

      dispatch({
        type: PROGRAM_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: PROGRAM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single program details
export const getProgramDetails = (program_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROGRAM_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/programs/${program_id}`, config);

    dispatch({
      type: PROGRAM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROGRAM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Edit single program details
export const editProgram =
  (program_id, values) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROGRAM_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/programs/${program_id}`,
        values,
        config
      );

      dispatch({
        type: PROGRAM_EDIT_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Program updated', 'success'));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: PROGRAM_EDIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Add new program
export const addProgram = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROGRAM_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/programs`, values, config);

    dispatch({
      type: PROGRAM_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Program added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: PROGRAM_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete a program
export const deleteProgram = (program_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROGRAM_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/programs/${program_id}`);

    dispatch({
      type: PROGRAM_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: PROGRAM_LIST_REMOVE_ITEM,
      payload: program_id,
    });
    dispatch(setAlert('Program deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: PROGRAM_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
