import {
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_LIST_RESET,
  BATCH_LIST_REMOVE_ITEM,
  BATCH_DETAILS_REQUEST,
  BATCH_DETAILS_SUCCESS,
  BATCH_DETAILS_RESET,
  BATCH_DETAILS_FAIL,
  BATCH_EDIT_REQUEST,
  BATCH_EDIT_SUCCESS,
  BATCH_EDIT_FAIL,
  BATCH_ADD_REQUEST,
  BATCH_ADD_SUCCESS,
  BATCH_ADD_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAIL,
  BATCH_ADD_RESET,
  BATCH_DELETE_RESET,
  BATCH_EDIT_RESET,
} from '../constants/batchConstants';

export const batchListReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_LIST_REQUEST:
      return { loading: true };
    case BATCH_LIST_SUCCESS:
      return {
        loading: false,
        batches: action.payload,
      };
    case BATCH_LIST_REMOVE_ITEM:
      return {
        ...state,
        batches: state.batches.filter(
          (batch) => batch.batch_id !== action.payload
        ),
      };
    case BATCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const batchDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DETAILS_REQUEST:
      return { loading: true };
    case BATCH_DETAILS_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
      };
    case BATCH_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const batchEditReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_EDIT_REQUEST:
      return { loading: true };
    case BATCH_EDIT_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
        success: true,
      };
    case BATCH_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case BATCH_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const batchAddReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_ADD_REQUEST:
      return { loading: true };
    case BATCH_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        batch: action.payload,
      };
    case BATCH_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case BATCH_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const batchDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DELETE_REQUEST:
      return { loading: true };
    case BATCH_DELETE_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
      };
    case BATCH_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
