import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Meta from '../../components/Meta';
import StyledTableCell from '../../components/StyledTableCell';

import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import Message from '../../components/Message';
import { deleteTeacher, listTeachers } from '../../actions/teacherActions';
import {
  TEACHER_ADD_RESET,
  TEACHER_EDIT_RESET,
} from '../../constants/teacherConstants';
import { listDepartments } from '../../actions/departmentActions';
import debounce from 'lodash.debounce';

const TeacherListScreen = ({ history }) => {
  const dispatch = useDispatch();
  const teacherList = useSelector((state) => state.teacherList);
  const { loading, error, teachers, pages, page, total } = teacherList;

  const teacherAdd = useSelector((state) => state.teacherAdd);
  const { success: successAdd } = teacherAdd;

  const teacherEdit = useSelector((state) => state.teacherEdit);
  const { success: successEdit } = teacherEdit;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = useState({
    teacher_name: '',
    teacher_designation: '',
    teacher_email: '',
    dept_id: '',
    sortBy: 'dept_id',
    sortingOrder: 'DESC',
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);

  useEffect(() => {
    if (successAdd) {
      dispatch({ type: TEACHER_ADD_RESET });
    }
    if (successEdit) {
      dispatch({ type: TEACHER_EDIT_RESET });
    }

    if (!departments) {
      dispatch(listDepartments());
    }

    dispatch(
      listTeachers(
        values.dept_id,
        values.teacher_name,
        values.teacher_email,
        values.teacher_designation,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, [dispatch, successAdd, successEdit, values.dept_id, pageNumber, pageSize]);

  const loadData = (values) => {
    dispatch(
      listTeachers(
        values.dept_id,
        values.teacher_name,
        values.teacher_email,
        values.teacher_designation,
        values.sortBy,
        values.sortingOrder,
        pageNumber,
        pageSize
      )
    );
  };

  const handleDelete = (teacher_id) => {
    if (window.confirm('Are you sure you want to delete this teacher?')) {
      dispatch(deleteTeacher(teacher_id));
    }
  };

  const handleDepartmentChange = (event) => {
    setPageNumber(1);
    setValues({ ...values, dept_id: event.target.value });
  };

  const delayedQuery = useRef(
    debounce((values) => loadData(values), 1000)
  ).current;

  const handleChange = (prop) => (event) => {
    setPageNumber(1);
    setValues({ ...values, [prop]: event.target.value });
    delayedQuery({ ...values, [prop]: event.target.value });
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPageSize(event.target.value);
  };

  return (
    <Container>
      <Grid container spacing={2} mb={2}>
        <Meta
          title={'Teachers'}
          description={'Teachers of Notre Dame University Bangladesh'}
          keywords={'ndub teachers, add ndub teachers, ndub teacher'}
        />
        {/* <h1 className="primary-header">Department List Screen</h1> <br /> */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/teachers/add')}
            disableElevation
          >
            <AddIcon /> &nbsp; Teacher
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.teacher_name}
            onChange={handleChange('teacher_name')}
            label="Name"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.teacher_designation}
            onChange={handleChange('teacher_designation')}
            label="Designation"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={values.teacher_email}
            onChange={handleChange('teacher_email')}
            label="Email"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <TextField
            select
            value={values.dept_id}
            label="Department"
            onChange={(event) => handleDepartmentChange(event)}
            style={{ width: '100%' }}
            disabled={loadingDepartments}
            size="small"
          >
            <MenuItem value="">All</MenuItem>
            {departments?.map((department) => (
              <MenuItem value={department.dept_id} key={department.dept_id}>
                {department.dept_name}
              </MenuItem>
            ))}
          </TextField>
          {loadingDepartments && <LinearProgress />}
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortBy}
            label="Sort By"
            onChange={handleChange('sortBy')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="created_at">Creation Time</MenuItem>
            <MenuItem value="updated_at">Update Time</MenuItem>
            <MenuItem value="teacher_name">Name</MenuItem>
            <MenuItem value="teacher_email">Email</MenuItem>
            <MenuItem value="teacher_designation">Designation</MenuItem>
            <MenuItem value="dept_id">Department</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={values.sortingOrder}
            label="Order"
            onChange={handleChange('sortingOrder')}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Pagination
              disabled={loading}
              count={pages ? pages : pagesFromAPI}
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
            <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
            <FormControl size="small">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
            {total && (
              <small style={{ marginLeft: '20px' }}>
                {total} records found
              </small>
            )}
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Teacher Name</StyledTableCell>
              <StyledTableCell align="left">Designation</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Department</StyledTableCell>
              <StyledTableCell align="left">URL</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
              <StyledTableCell align="center">View/Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers?.map((teacher, index) => (
              <TableRow
                key={teacher.teacher_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {teacher.teacher_name}
                </TableCell>
                <TableCell align="left">
                  {teacher.teacher_designation}
                </TableCell>
                <TableCell align="left">{teacher.teacher_email}</TableCell>
                <TableCell align="left">{teacher.dept_name}</TableCell>
                <TableCell align="left">
                  <a href={teacher.dept_url} target="_blank" rel="noreferrer">
                    <IconButton aria-label="delete" size="small">
                      <OpenInNewIcon color="primary" fontSize="small" />
                    </IconButton>
                  </a>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(teacher.teacher_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/teachers/edit/${teacher.teacher_id}`}>
                    <IconButton aria-label="delete" size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default TeacherListScreen;
