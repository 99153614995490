import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

import NDUB_LOGO from '../img/NDUB_Logo.png';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import { register, emailVerify } from '../actions/userActions';
import { useEffect } from 'react';
import setAuthToken from '../utils/setAuthToken';
import {
  USER_EMAIL_VERIFY_RESET,
  USER_LOGIN_RESET,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_REGISTER_RESET,
} from '../constants/userConstants';
// import setAuthToken from '../utils/setAuthToken';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RegisterVerifyScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  //   const [searchParams] = useSearchParams();

  let query = useQuery();

  console.log(query.get('token'));

  const [token, setToken] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);

  // console.log(location.search);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
  const { loading } = userVerifyEmail;

  // const userRegister = useSelector((state) => state.userRegister);
  // const { loading } = userRegister;

  useEffect(() => {
    if (!query.get('token')) {
      history.push('/login');
    } else {
      setToken(query.get('token'));
      if (query.get('code')) {
        setVerificationCode(query.get('code'));
        dispatch(emailVerify(query.get('token'), query.get('code')));
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      setAuthToken(userInfo.token);
      history.push('/');
    }
  }, [history, userInfo]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(emailVerify(token, verificationCode));
  };

  const resetStuffs = () => {
    dispatch({ type: USER_LOGIN_RESET });
    dispatch({ type: USER_REGISTER_RESET });
    dispatch({ type: USER_PASSWORD_RESET_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_CODE_RESET });
    dispatch({ type: USER_PASSWORD_RESET_VERIFY_EMAIL_RESET });
    dispatch({ type: USER_EMAIL_VERIFY_RESET });
  };

  return (
    <Container>
      <Grid
        container
        className="login-container"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">Verify your email address</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header mb-20">Verify</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                variant="outlined"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                label="Verification Code"
                style={{ width: '100%', marginBottom: '20px' }}
              />

              <i className="fas fa-user" style={{ color: '#000' }} />
              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Verify
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Already have an account?{' '}
              <Link
                to="/login"
                onClick={() => resetStuffs()}
                style={{ textDecoration: 'underline' }}
              >
                Login
              </Link>{' '}
              | Did not receive code?{' '}
              <Link
                to="/register"
                onClick={() => dispatch({ type: USER_REGISTER_RESET })}
                style={{ textDecoration: 'underline' }}
              >
                Start over
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default RegisterVerifyScreen;
