import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EVAL_ANS_SUBMIT_RESET } from '../../constants/evalAnsConstants';
import EvaluationAdminScreen from './EvaluationAdminScreen';
import EvaluationStudentScreen from './EvaluationStudentScreen';
import EvaluationTeacherScreen from './EvaluationTeacherScreen';

const EvaluationScreen = ({ theme }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const { user } = userInfo;

  useEffect(() => {
    dispatch({ type: EVAL_ANS_SUBMIT_RESET });
  }, []);

  return user.is_student ? (
    <EvaluationStudentScreen />
  ) : user.is_admin || user.is_exam_admin ? (
    <EvaluationAdminScreen />
  ) : (
    <EvaluationTeacherScreen theme={theme} />
  );
};

export default EvaluationScreen;
