import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStudentDetails,
  registerStudent,
  updateStudent,
} from '../../actions/studentActions';
import { listBatches } from '../../actions/batchActions';
import { listPrograms } from '../../actions/programActions';
import { listDepartments } from '../../actions/departmentActions';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { Link } from 'react-router-dom';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import { listSemesters } from '../../actions/semesterActions';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';

const StudentEditScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    stu_id: '',
    stu_name: '',
    batch_id: '',
    program_id: ' ',
    dept_id: ' ',
    batch_id: ' ',
    sem_name: '',
    stu_email: '',
    stu_current_sem: '',
    stu_mobile_number: '',
  });

  const [semester, setSemester] = useState(null);
  const [batch, setBatch] = useState(null);

  const studentDetails = useSelector((state) => state.studentDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    student,
  } = studentDetails;

  const studentUpdate = useSelector((state) => state.studentUpdate);
  const { loading, error, student: updatedStudent, success } = studentUpdate;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches, error: errorBatches } = batchList;

  const programList = useSelector((state) => state.programList);
  const { loading: loadingPrograms, programs } = programList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters } = semesterList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (!batches) {
      dispatch(listBatches());
    }
    if (!programs) {
      dispatch(listPrograms());
    }
    if (!departments) {
      dispatch(listDepartments());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
    if (semesters && student) {
      setSemester(semesters.filter((sem) => sem.sem_id == student.sem_id)[0]);
    }
    if (batches && student) {
      setBatch(
        batches.filter((batch) => batch.batch_id == student.batch_id)[0]
      );
    }
    // if (success) {
    //   setTimeout(() => {
    //     history.push('/students');
    //   }, 2000);
    // } else {
    //   if (!student || student.stu_serial_id != match.params.id) {
    //     dispatch(getStudentDetails(match.params.id));
    //   } else {
    //     setValues({ ...student });
    //   }
    // }
  }, [dispatch, match.params.id, student, success, semesters, batches]);

  useEffect(() => {
    dispatch(getStudentDetails(match.params.id));
  }, []);

  useEffect(() => {
    if (student) {
      setValues({ ...student });
    }
    if (semesters && updatedStudent) {
      setSemester(
        semesters.filter((sem) => sem.sem_id == updatedStudent.sem_id)[0]
      );
    }
    if (batches && updatedStudent) {
      setBatch(
        batches.filter((batch) => batch.batch_id == updatedStudent.batch_id)[0]
      );
    }
  }, [student, semesters, batches, updatedStudent]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // const handleDepartmentChange = (event) => {
  //   setValues({ ...values, dept_id: event.target.value });
  //   dispatch(listPrograms(event.target.value));
  // };

  // const handleProgramChange = (event) => {
  //   setValues({ ...values, program_id: event.target.value });
  //   dispatch(listBatches(event.target.value));
  // };

  const handleBatchChange = (event) => {
    setValues({ ...values, batch_id: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateStudent(match.params.id, {
        ...values,
        batch_id: batch.batch_id,
        sem_id: semester.sem_id,
      })
    );
  };

  return (
    <Container>
      <Meta
        title={'Edit Student'}
        description={
          'Edit student to the student database of Notre Dame University Bangladesh'
        }
        keywords={'Edit new student, register student'}
      />{' '}
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Edit student</Typography>
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12} sm={8}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  animation="wave"
                  component="div"
                  variant={'h3'}
                  style={{ marginBottom: '-20px' }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.stu_name}
                  onChange={handleChange('stu_name')}
                  label="Name"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  value={values.stu_id}
                  onChange={handleChange('stu_id')}
                  label="Student ID"
                  style={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BatchSearchableDropdown batch={batch} setBatch={setBatch} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SemesterSearchableDropdown
                  semester={semester}
                  setSemester={setSemester}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.stu_email}
                  onChange={handleChange('stu_email')}
                  label="Student Email"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.stu_mobile_number}
                  onChange={handleChange('stu_mobile_number')}
                  label="Mobile Number"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Link to={'/students'}>
                  <Button
                    variant="outlined"
                    disableElevation
                    style={{ marginRight: '10px' }}
                  >
                    Back
                  </Button>
                </Link>
                <Button variant="contained" disableElevation type="submit">
                  Submit{' '}
                  {loading && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default StudentEditScreen;
