import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../actions/userActions';
import Message from '../Message';
import { USER_PASSWORD_CHANGE_RESET } from '../../constants/userConstants';

export default function PasswordChangePopup() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userPasswordChange = useSelector((state) => state.userPasswordChange);
  const { loading: loadingPasswordChange, success } = userPasswordChange;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPasswordChange = () => {
    // window.alert(phone);
    dispatch(changePassword(currentPassword, newPassword, confirmPassword));
  };

  useEffect(() => {
    dispatch({ type: USER_PASSWORD_CHANGE_RESET });
  }, []);

  useEffect(() => {
    if (success) {
      handleClose();
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  }, [success]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Button size="medium" onClick={handleClickOpen}>
        Change Password
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your current password and new password to change your password
          </DialogContentText>

          <TextField
            variant="outlined"
            style={{ width: '100%', marginBottom: '20px', marginTop: '20px' }}
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) =>
                      setShowCurrentPassword(!showCurrentPassword)
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Current Password"
          />
          <TextField
            variant="outlined"
            style={{ width: '100%', marginBottom: '20px' }}
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => setShowNewPassword(!showNewPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="New Password"
          />
          <TextField
            variant="outlined"
            style={{ width: '100%', marginBottom: '20px' }}
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) =>
                      setShowConfirmPassword(!showConfirmPassword)
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Confirm Password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={(e) => onPasswordChange(e)}
            disabled={loadingPasswordChange}
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
      {msg?.length > 0 && <Message />}
    </>
  );
}
