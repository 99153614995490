import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CourseSearchableDropdown from '../../components/dropdowns/CourseSearchableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../actions/courseActions';
import TeacherSearchableDropdown from '../../components/dropdowns/TeacherSearchableDropdown';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import { listTeachers } from '../../actions/teacherActions';
import { listStudents } from '../../actions/studentActions';
import { listSemesters } from '../../actions/semesterActions';
import { listBatches } from '../../actions/batchActions';
import StudentSearchableDropdown from '../../components/dropdowns/StudentSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import { addAllocation } from '../../actions/allocationActions';
import { Link } from 'react-router-dom';
import Message from '../../components/Message';

const CourseAllocationAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const teacherList = useSelector((state) => state.teacherList);
  const { teachers } = teacherList;

  const studentList = useSelector((state) => state.studentList);
  const { students } = studentList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters } = semesterList;

  const batchList = useSelector((state) => state.batchList);
  const { batches } = batchList;

  const allocationAdd = useSelector((state) => state.allocationAdd);
  const { allocation, success, loading: loadingAllocationAdd } = allocationAdd;

  const [student, setStudent] = useState(null);
  const [semester, setSemester] = useState(null);
  const [batch, setBatch] = useState(null);

  const [allocations, setAllocations] = useState([
    {
      id: uuid(),
      course_id: '',
      teacher_id: '',
      is_repeat_or_retake: false,
    },
  ]);

  // const handleChangeAllocations = (id, e) => {
  //   const newAllocation = allocations.map((i) => {
  //     if (id === i.id) {
  //       if (e.target.name === 'course_id') {
  //         i.course_id = e.target.value.course_id;
  //       }
  //       // i[e.target.name] = e.target.value;
  //     }
  //     return i;
  //   });

  //   setAllocations(newAllocation);
  // };

  // useEffect(() => {
  //   if (success) {
  //     history.push('/allocations');
  //   }
  // }, [success]);

  const handleChangeCourse = (c, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        c === null ? (i.course_id = '') : (i.course_id = c.course_id);
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleChangeTeacher = (t, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        t === null ? (i.teacher_id = '') : (i.teacher_id = t.teacher_id);
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleIsRepeatOrRetake = (e, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        i.is_repeat_or_retake = !i.is_repeat_or_retake;
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleChangeRepeatRetakeBatch = (a, id) => {
    const newAllocation = allocations.map((i) => {
      if (id === i.id) {
        a === null
          ? (i.repeat_retake_with_batch = '')
          : (i.repeat_retake_with_batch = a.batch_id);
      }
      return i;
    });
    setAllocations(newAllocation);
  };

  const handleAddAllocations = () => {
    setAllocations([
      ...allocations,
      {
        id: uuid(),
        course_id: '',
        teacher_id: '',
        is_repeat_or_retake: false,
      },
    ]);
  };

  const handleRemoveAllocations = (id) => {
    let values = [...allocations];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setAllocations([...values]);
  };

  useEffect(() => {
    if (!courses) {
      dispatch(listCourses());
    }
    if (!teachers) {
      dispatch(listTeachers());
    }
    if (!students) {
      dispatch(listStudents());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
    if (!batches) {
      dispatch(listBatches());
    }
  }, []);

  const onSubmit = () => {
    if (!semester || !student || allocations[0].course_id === '') {
      window.alert('Please add allocations!');
    } else
      dispatch(
        addAllocation({
          semester_id: semester.sem_id,
          student_serial_id: student.stu_serial_id,
          batch_id: student.batch_id,
          details: allocations,
        })
      );
  };

  return (
    <div>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Add new course allocation for single student
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StudentSearchableDropdown
            student={student}
            setStudent={setStudent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SemesterSearchableDropdown
            semester={semester}
            setSemester={setSemester}
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" style={{ padding: '10px 20px 10px 20px' }}>
            <p style={{ margin: 0 }}>Add Courses & Teachers</p>
            {allocations.map((allocation, index) => (
              <Grid container spacing={3} key={allocation.id} mb={1}>
                <Grid item xs={12} sm={3}>
                  <CourseSearchableDropdown
                    setCourse={handleChangeCourse}
                    id={allocation.id}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TeacherSearchableDropdown
                    setTeacher={handleChangeTeacher}
                    id={allocation.id}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          allocations.find((a) => {
                            return a.id === allocation.id;
                          }).is_repeat_or_retake
                        }
                        onChange={(e) =>
                          handleIsRepeatOrRetake(e, allocation.id)
                        }
                      />
                    }
                    label="Repeat/Retake"
                    labelPlacement="end"
                  />
                </Grid>
                {allocations.find((a) => {
                  return a.id === allocation.id;
                }).is_repeat_or_retake && (
                  <Grid item xs={12} sm={2}>
                    <BatchSearchableDropdown
                      setBatch={handleChangeRepeatRetakeBatch}
                      id={allocation.id}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={2} align="left">
                  <IconButton
                    disabled={allocations.length === 1}
                    onClick={() => handleRemoveAllocations(allocation.id)}
                    color="danger"
                  >
                    <CancelIcon />
                  </IconButton>
                  {allocations.length - 1 === index && (
                    <IconButton
                      onClick={() => handleAddAllocations()}
                      color="primary"
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}
                </Grid>{' '}
              </Grid>
            ))}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Link to={'/allocations'}>
            <Button variant="outlined" disableElevation>
              Back
            </Button>
          </Link>
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: '10px' }}
            onClick={(e) => onSubmit(e)}
          >
            Add{' '}
            {loadingAllocationAdd && (
              <>
                &nbsp;
                <CircularProgress color="white" size={20} thickness={5} />
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </div>
  );
};

export default CourseAllocationAddScreen;
