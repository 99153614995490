export const ALLOCATION_LIST_REQUEST = 'ALLOCATION_LIST_REQUEST';
export const ALLOCATION_LIST_SUCCESS = 'ALLOCATION_LIST_SUCCESS';
export const ALLOCATION_LIST_FAIL = 'ALLOCATION_LIST_FAIL';
export const ALLOCATION_LIST_RESET = 'ALLOCATION_LIST_RESET';
export const ALLOCATION_LIST_REMOVE_ITEM = 'ALLOCATION_LIST_REMOVE_ITEM';

export const ALLOCATION_DETAILS_REQUEST = 'ALLOCATION_DETAILS_REQUEST';
export const ALLOCATION_DETAILS_SUCCESS = 'ALLOCATION_DETAILS_SUCCESS';
export const ALLOCATION_DETAILS_FAIL = 'ALLOCATION_DETAILS_FAIL';
export const ALLOCATION_DETAILS_RESET = 'ALLOCATION_DETAILS_RESET';

export const ALLOCATION_ADD_REQUEST = 'ALLOCATION_ADD_REQUEST';
export const ALLOCATION_ADD_SUCCESS = 'ALLOCATION_ADD_SUCCESS';
export const ALLOCATION_ADD_FAIL = 'ALLOCATION_ADD_FAIL';
export const ALLOCATION_ADD_RESET = 'ALLOCATION_ADD_RESET';

export const ALLOCATION_ADD_BULK_REQUEST = 'ALLOCATION_ADD_BULK_REQUEST';
export const ALLOCATION_ADD_BULK_SUCCESS = 'ALLOCATION_ADD_BULK_SUCCESS';
export const ALLOCATION_ADD_BULK_FAIL = 'ALLOCATION_ADD_BULK_FAIL';
export const ALLOCATION_ADD_BULK_RESET = 'ALLOCATION_ADD_BULK_RESET';

export const ALLOCATION_DELETE_REQUEST = 'ALLOCATION_DELETE_REQUEST';
export const ALLOCATION_DELETE_SUCCESS = 'ALLOCATION_DELETE_SUCCESS';
export const ALLOCATION_DELETE_FAIL = 'ALLOCATION_DELETE_FAIL';
export const ALLOCATION_DELETE_RESET = 'ALLOCATION_DELETE_RESET';

export const ALLOCATION_EDIT_REQUEST = 'ALLOCATION_EDIT_REQUEST';
export const ALLOCATION_EDIT_SUCCESS = 'ALLOCATION_EDIT_SUCCESS';
export const ALLOCATION_EDIT_FAIL = 'ALLOCATION_EDIT_FAIL';
export const ALLOCATION_EDIT_RESET = 'ALLOCATION_EDIT_RESET';
