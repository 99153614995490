import {
  EVAL_WRITTEN_QUES_LIST_REQUEST,
  EVAL_WRITTEN_QUES_LIST_SUCCESS,
  EVAL_WRITTEN_QUES_LIST_FAIL,
  EVAL_WRITTEN_QUES_LIST_RESET,
  EVAL_WRITTEN_QUES_LIST_REMOVE_ITEM,
} from '../constants/evalWrittenQuesConstants';

export const evalWrittenQuesListReducer = (state = {}, action) => {
  switch (action.type) {
    case EVAL_WRITTEN_QUES_LIST_REQUEST:
      return { loading: true };
    case EVAL_WRITTEN_QUES_LIST_SUCCESS:
      return {
        loading: false,
        evalWrittenQuestions: action.payload,
      };
    case EVAL_WRITTEN_QUES_LIST_REMOVE_ITEM:
      return {
        ...state,
        evalWrittenQuestions: state.evalWrittenQuestions.filter(
          (evalMcqQuestion) => evalMcqQuestion._id !== action.payload
        ),
      };
    case EVAL_WRITTEN_QUES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EVAL_WRITTEN_QUES_LIST_RESET:
      return {};
    default:
      return state;
  }
};

// export const catalogDetailsReducer = (
//   state = { loading: false, catalog: {} },
//   action
// ) => {
//   switch (action.type) {
//     case CATALOG_DETAILS_REQUEST:
//       return { loading: true, ...state };
//     case CATALOG_DETAILS_SUCCESS:
//       return {
//         loading: false,
//         catalog: action.payload,
//       };
//     case CATALOG_DETAILS_FAIL:
//       return { loading: false, error: action.payload };
//     case CATALOG_DETAILS_RESET:
//       return { loading: false, catalog: {} };
//     default:
//       return state;
//   }
// };

// export const catalogDeleteReducer = (state = {}, action) => {
//   switch (action.type) {
//     case CATALOG_DELETE_REQUEST:
//       return { loading: true };
//     case CATALOG_DELETE_SUCCESS:
//       return { loading: false, success: true };
//     case CATALOG_DELETE_FAIL:
//       return { loading: false, error: action.payload };
//     case CATALOG_DELETE_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

// export const catalogCreateReducer = (state = {}, action) => {
//   switch (action.type) {
//     case CATALOG_CREATE_REQUEST:
//       return { loading: true };
//     case CATALOG_CREATE_SUCCESS:
//       return { loading: false, success: true, catalog: action.payload };
//     case CATALOG_CREATE_FAIL:
//       return { loading: false, error: action.payload };
//     case CATALOG_CREATE_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

// export const catalogUpdateReducer = (state = { catalog: {} }, action) => {
//   switch (action.type) {
//     case CATALOG_UPDATE_REQUEST:
//       return { loading: true };
//     case CATALOG_UPDATE_SUCCESS:
//       return { loading: false, success: true, catalog: action.payload };
//     case CATALOG_UPDATE_FAIL:
//       return { loading: false, error: action.payload };
//     case CATALOG_UPDATE_RESET:
//       return { catalog: {} };
//     default:
//       return state;
//   }
// };

// export const productReviewCreateReducer = (state = {}, action) => {
//   switch (action.type) {
//     case CATALOG_CREATE_REVIEW_REQUEST:
//       return { loading: true };
//     case CATALOG_CREATE_REVIEW_SUCCESS:
//       return { loading: false, success: true };
//     case CATALOG_CREATE_REVIEW_FAIL:
//       return { loading: false, error: action.payload };
//     case CATALOG_CREATE_REVIEW_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

// export const productTopRatedReducer = (state = { products: [] }, action) => {
//   switch (action.type) {
//     case CATALOG_TOP_REQUEST:
//       return { loading: true, products: [] };
//     case CATALOG_TOP_SUCCESS:
//       return { loading: false, products: action.payload };
//     case CATALOG_TOP_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
