import axios from 'axios';

import {
  EVALUATION_COURSE_LIST_REQUEST,
  EVALUATION_COURSE_LIST_SUCCESS,
  EVALUATION_COURSE_LIST_FAIL,
  EVALUATION_COURSE_DETAILS_REQUEST,
  EVALUATION_COURSE_DETAILS_SUCCESS,
  EVALUATION_COURSE_DETAILS_FAIL,
  EVALUATION_COURSE_CALCULATE_REQUEST,
  EVALUATION_COURSE_CALCULATE_SUCCESS,
  EVALUATION_COURSE_CALCULATE_FAIL,
  EVALUATION_OVERALL_RESULT_REQUEST,
  EVALUATION_OVERALL_RESULT_SUCCESS,
  EVALUATION_OVERALL_RESULT_FAIL,
  EVALUATION_PER_QUES_RESULT_REQUEST,
  EVALUATION_PER_QUES_RESULT_SUCCESS,
  EVALUATION_PER_QUES_RESULT_FAIL,
  EVALUATION_RESULT_PUBLISH_REQUEST,
  EVALUATION_RESULT_PUBLISH_SUCCESS,
  EVALUATION_RESULT_PUBLISH_FAIL,
  EVALUATION_OVERALL_RESULT_DETAILS_REQUEST,
  EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS,
  EVALUATION_OVERALL_RESULT_DETAILS_FAIL,
  EVALUATION_COURSE_LIST_TEACHER_REQUEST,
  EVALUATION_COURSE_LIST_TEACHER_SUCCESS,
  EVALUATION_COURSE_LIST_TEACHER_FAIL,
  EVALUATION_REPORT_TEACHER_REQUEST,
  EVALUATION_REPORT_TEACHER_SUCCESS,
  EVALUATION_REPORT_TEACHER_FAIL,
  EVALUATION_RESULT_MAIL_REQUEST,
  EVALUATION_RESULT_MAIL_SUCCESS,
  EVALUATION_RESULT_MAIL_FAIL,
  EVALUATION_RESULT_PDF_REQUEST,
  EVALUATION_RESULT_PDF_SUCCESS,
  EVALUATION_RESULT_PDF_FAIL,
  EVALUATION_STUDENT_LIST_SUCCESS,
  EVALUATION_STUDENT_LIST_REQUEST,
  EVALUATION_STUDENT_LIST_FAIL,
  EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST,
  EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS,
  EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL,
} from '../constants/evaluationConstants';
import { setAlert } from './alertActions';
import { saveAs } from 'file-saver';

// Get all evaluation courses
// @access admin
export const listEvaluationCourses =
  (
    sem_id = '',
    program_id = '',
    course_id = '',
    batch_id = '',
    teacher_id = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_COURSE_LIST_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/courses/allocation?sem_id=${sem_id}&program_id=${program_id}&course_id=${course_id}&batch_id=${batch_id}&teacher_id=${teacher_id}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: EVALUATION_COURSE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_COURSE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get all evaluation courses
// @access teacher
export const listEvaluationCoursesTeacher = (values) => async (dispatch) => {
  try {
    dispatch({ type: EVALUATION_COURSE_LIST_TEACHER_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let { sem_id, course_id } = values;

    const { data } = await axios.get(
      `/api/teachers/report?sem_id=${sem_id}&course_id=${course_id}`,
      config
    );

    dispatch({
      type: EVALUATION_COURSE_LIST_TEACHER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: EVALUATION_COURSE_LIST_TEACHER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get single course's evaluation result
// @access teacher
export const getEvaluationCourseReportTeacher =
  (report_id) => async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_REPORT_TEACHER_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/teachers/report/${report_id}`,
        config
      );

      dispatch({
        type: EVALUATION_REPORT_TEACHER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_REPORT_TEACHER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single evaluation courses
export const getEvaluationCourseDetails = (values) => async (dispatch) => {
  try {
    dispatch({ type: EVALUATION_COURSE_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let { sem_id, course_id, batch_id, teacher_id } = values;

    const { data } = await axios.get(
      `/api/courses/allocation/${teacher_id}/${sem_id}/${course_id}?batch_id=${batch_id}`,
      config
    );

    dispatch({
      type: EVALUATION_COURSE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: EVALUATION_COURSE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all courses
export const evaluationCourseCalculate = (values) => async (dispatch) => {
  try {
    dispatch({ type: EVALUATION_COURSE_CALCULATE_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `/api/evaluations/evaluate`,
      values,
      config
    );

    dispatch({
      type: EVALUATION_COURSE_CALCULATE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Calculated Successfully', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: EVALUATION_COURSE_CALCULATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all courses
export const getEvaluationOverallResult =
  (teacher_id = '', sem_id = '', course_id = '', batch_id = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_OVERALL_RESULT_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/evaluations/report/${teacher_id}?sem_id=${sem_id}&course_id=${course_id}/${batch_id}`,
        config
      );

      dispatch({
        type: EVALUATION_OVERALL_RESULT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_OVERALL_RESULT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get evaluation report
// @access teacher
export const getEvaluationReportTeacher = (report_id) => async (dispatch) => {
  try {
    dispatch({ type: EVALUATION_REPORT_TEACHER_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/evaluations/report/${report_id}`,
      config
    );

    dispatch({
      type: EVALUATION_REPORT_TEACHER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: EVALUATION_REPORT_TEACHER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get all courses
export const getEvaluationOverallResultDetails =
  (teacher_id = '', sem_id = '', course_id = '', batch_id = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_OVERALL_RESULT_DETAILS_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/evaluations/report/details/${teacher_id}/${sem_id}/${course_id}/${batch_id}`,
        config
      );

      dispatch({
        type: EVALUATION_OVERALL_RESULT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_OVERALL_RESULT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get per ques mcq evaluation result
export const getEvaluationPerQuesResult =
  (teacher_id = '', sem_id = '', course_id = '', batch_id = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_PER_QUES_RESULT_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/evaluations/report/${teacher_id}/${sem_id}/${course_id}/${batch_id}`,
        config
      );

      dispatch({
        type: EVALUATION_PER_QUES_RESULT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_PER_QUES_RESULT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get per ques written evaluation result
export const getEvaluationPerQuesWrittenResult =
  (teacher_id = '', sem_id = '', course_id = '', batch_id = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_PER_QUES_WRITTEN_RESULT_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/evaluations/written-report/${teacher_id}/${sem_id}/${course_id}/${batch_id}`,
        config
      );

      dispatch({
        type: EVALUATION_PER_QUES_WRITTEN_RESULT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_PER_QUES_WRITTEN_RESULT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Publish evaluation result
export const publishEvaluationResult =
  (id, publishedCheck) => async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_RESULT_PUBLISH_REQUEST });

      const { data } = await axios.put(
        `/api/evaluations/publish/${id}/${publishedCheck}`
      );

      dispatch({
        type: EVALUATION_RESULT_PUBLISH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_RESULT_PUBLISH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// evaluation result email request
export const mailEvaluationResult = (id) => async (dispatch) => {
  try {
    dispatch({ type: EVALUATION_RESULT_MAIL_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/evaluations/mail/${id}`, config);

    dispatch({
      type: EVALUATION_RESULT_MAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: EVALUATION_RESULT_MAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// evaluation result email request
export const pdfEvaluationResult =
  (id, teacher_name, course_name, sem_name, sem_year, save = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_RESULT_PDF_REQUEST });

      const config = {
        responseType: 'blob',
      };

      const { data } = await axios.get(`/api/evaluations/pdf/${id}`, config);

      dispatch({
        type: EVALUATION_RESULT_PDF_SUCCESS,
        payload: data,
      });

      const currentTime = new Date();
      const fileName = `${teacher_name}_${course_name}_${sem_name}_${sem_year}_${currentTime.toLocaleTimeString()}.pdf`;

      const pdfBlob = new Blob([data], { type: 'application/pdf' });

      if (save) {
        saveAs(pdfBlob, `${fileName}`);
      } else {
        //Build a URL from the file
        const fileURL = URL.createObjectURL(pdfBlob);

        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_RESULT_PDF_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get all evaluation student
// @access admin
export const listEvaluationStudents =
  (
    sem_id = '',
    batch_id = '',
    teacher_id = '',
    course_id = '',
    hasevaluated = '',
    sortBy = '',
    sortingOrder = ''
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_STUDENT_LIST_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/evaluations/stats?sem_id=${sem_id}&batch_id=${batch_id}&teacher_id=${teacher_id}&course_id=${course_id}&hasevaluated=${hasevaluated}&sortBy=${sortBy}&sortingOrder=${sortingOrder}`,
        config
      );

      dispatch({
        type: EVALUATION_STUDENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: EVALUATION_STUDENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
