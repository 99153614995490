import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CourseSearchableDropdown from '../../components/dropdowns/CourseSearchableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { listCourses } from '../../actions/courseActions';
import TeacherSearchableDropdown from '../../components/dropdowns/TeacherSearchableDropdown';
import { listTeachers } from '../../actions/teacherActions';
import { listStudents } from '../../actions/studentActions';
import { listSemesters } from '../../actions/semesterActions';
import StudentSearchableDropdown from '../../components/dropdowns/StudentSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import {
  addAllocation,
  courseAllocationsDetails,
  editAllocation,
} from '../../actions/allocationActions';
import { Link } from 'react-router-dom';
import Message from '../../components/Message';

const CourseAllocationEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const teacherList = useSelector((state) => state.teacherList);
  const { teachers } = teacherList;

  const studentList = useSelector((state) => state.studentList);
  const { students } = studentList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters } = semesterList;

  const allocationDetails = useSelector((state) => state.allocationDetails);
  const { allocation, loading: loadingAllocationAdd } = allocationDetails;

  const allocationEdit = useSelector((state) => state.allocationEdit);
  const {
    allocation: editedAllocation,
    success: successEdit,
    loading: loadingAllocationEdit,
  } = allocationEdit;

  const [student, setStudent] = useState(null);
  const [semester, setSemester] = useState(null);
  const [course, setCourse] = useState(null);
  const [teacher, setTeacher] = useState(null);

  useEffect(() => {
    dispatch(courseAllocationsDetails(match.params.id));
  }, []);

  useEffect(() => {
    if (allocation) {
      setStudent({
        stu_serial_id: allocation.stu_serial_id,
        stu_id: allocation.stu_id,
        stu_name: allocation.stu_name,
        stu_email: allocation.stu_email,
        stu_mobile_number: allocation.stu_mobile_number,
      });
      setSemester({
        sem_id: allocation.sem_id,
        sem_name: allocation.sem_name,
        sem_start: allocation.sem_start,
        sem_end: allocation.sem_end,
        sem_year: allocation.sem_year,
      });
      setCourse({
        course_id: allocation.course_id,
        course_name: allocation.course_name,
        course_code: allocation.course_code,
        course_credit: allocation.course_credit,
        course_type: allocation.course_type,
      });
      setTeacher({
        teacher_id: allocation.teacher_id,
        teacher_name: allocation.teacher_name,
        teacher_email: allocation.teacher_email,
        teacher_designation: allocation.teacher_designation,
      });
    }
  }, [allocation]);

  // useEffect(() => {
  //   if (success) {
  //     history.push('/allocations');
  //   }
  // }, [success]);

  useEffect(() => {
    if (!courses) {
      dispatch(listCourses());
    }
    if (!teachers) {
      dispatch(listTeachers());
    }
    if (!students) {
      dispatch(listStudents());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
  }, []);

  const onSubmit = () => {
    if (!semester || !student || !course || !teacher) {
      window.alert('Please add allocations!');
    } else
      dispatch(
        editAllocation(allocation.student_course_id, {
          semester_id: semester.sem_id,
          batch_id: allocation.batch_id,
          student_serial_id: allocation.student_serial_id,
          course_id: course.course_id,
          teacher_id: teacher.teacher_id,
        })
      );
  };

  return (
    <div>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Edit course allocation</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StudentSearchableDropdown
            student={student}
            setStudent={setStudent}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SemesterSearchableDropdown
            semester={semester}
            setSemester={setSemester}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CourseSearchableDropdown setCourse={setCourse} course={course} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TeacherSearchableDropdown
            setTeacher={setTeacher}
            teacher={teacher}
          />
        </Grid>

        <Grid item xs={12}>
          <Link to={'/allocations'}>
            <Button variant="outlined" disableElevation>
              Back
            </Button>
          </Link>
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: '10px' }}
            onClick={(e) => onSubmit(e)}
          >
            Add{' '}
            {loadingAllocationEdit && (
              <>
                &nbsp;
                <CircularProgress color="white" size={20} thickness={5} />
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </div>
  );
};

export default CourseAllocationEditScreen;
