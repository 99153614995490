import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const BatchSearchableDropdown = ({ batch, setBatch, id }) => {
  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches } = batchList;

  // Set default props for batches dropdown
  const defaultBatchProps = {
    options: batches ? batches : [],
    getOptionLabel: (batch) => batch.batch_name,
  };

  return (
    <>
      <Autocomplete
        {...defaultBatchProps}
        value={batch}
        onChange={(event, newValue) => {
          setBatch(newValue, id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Batch"
            variant="outlined"
            size="small"
          />
        )}
      />
      {loadingBatches && <LinearProgress />}
    </>
  );
};

export default BatchSearchableDropdown;
