import axios from 'axios';

import {
  STUDENT_COURSE_LIST_FAIL,
  STUDENT_COURSE_LIST_REQUEST,
  STUDENT_COURSE_LIST_SUCCESS,
  STUDENT_DELETE_FAIL,
  STUDENT_DELETE_REQUEST,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DETAILS_FAIL,
  STUDENT_DETAILS_REQUEST,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_LIST_FAIL,
  STUDENT_LIST_REMOVE_ITEM,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  STUDENT_REGISTER_FAIL,
  STUDENT_REGISTER_REQUEST,
  STUDENT_REGISTER_SUCCESS,
  STUDENT_BULK_REGISTER_FAIL,
  STUDENT_BULK_REGISTER_REQUEST,
  STUDENT_BULK_REGISTER_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
} from '../constants/studentConstants';
import { setAlert } from './alertActions';

// Get all students
export const listStudents =
  (
    stu_id = '',
    stu_name = '',
    stu_email = '',
    stu_mobile_number = '',
    sem_id = '',
    batch_id = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50000 // 50000 is set to load all students for the student dropdown component
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: STUDENT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/students?stu_id=${stu_id}&stu_name=${stu_name}&stu_email=${stu_email}&stu_mobile_number=${stu_mobile_number}&sem_id=${sem_id}&batch_id=${batch_id}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );
      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: STUDENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get all students
export const getStudentDetails = (stu_serial_id) => async (dispatch) => {
  try {
    dispatch({ type: STUDENT_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/students/${stu_serial_id}`, config);

    dispatch({
      type: STUDENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get current courses
export const listStudentCourses = () => async (dispatch) => {
  try {
    dispatch({ type: STUDENT_COURSE_LIST_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/student-course/student`, config);

    dispatch({
      type: STUDENT_COURSE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_COURSE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerStudent = (values) => async (dispatch) => {
  try {
    dispatch({
      type: STUDENT_REGISTER_REQUEST,
    });

    console.log(values);

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post('/api/students', values, config);
    dispatch({
      type: STUDENT_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Student registered', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: STUDENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const registerBulkStudent = (values) => async (dispatch) => {
  try {
    dispatch({
      type: STUDENT_BULK_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    const { data } = await axios.post('/api/students/bulk', values, config);
    dispatch({
      type: STUDENT_BULK_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(`${data.total_inserted} students added`, 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: STUDENT_BULK_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateStudent =
  (stu_serial_id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STUDENT_UPDATE_REQUEST,
      });

      console.log(values);

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/students/${stu_serial_id}`,
        values,
        config
      );

      dispatch({
        type: STUDENT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Student updated', 'success'));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: STUDENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteStudent = (stu_serial_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
      },
    };

    await axios.delete(`/api/students/${stu_serial_id}`, config);

    dispatch({
      type: STUDENT_DELETE_SUCCESS,
    });
    dispatch({
      type: STUDENT_LIST_REMOVE_ITEM,
      payload: stu_serial_id,
    });
    dispatch(setAlert('Student deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: STUDENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
