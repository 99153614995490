import {
  SMS_MULTI_SEND_FAIL,
  SMS_MULTI_SEND_REQUEST,
  SMS_MULTI_SEND_SUCCESS,
  SMS_SEND_FAIL,
  SMS_SEND_REQUEST,
  SMS_SEND_SUCCESS,
} from '../constants/smsConstants';

export const smsSendReducer = (state = {}, action) => {
  switch (action.type) {
    case SMS_SEND_REQUEST:
      return { loading: true };
    case SMS_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case SMS_SEND_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const smsMultiSendReducer = (state = {}, action) => {
  switch (action.type) {
    case SMS_MULTI_SEND_REQUEST:
      return { loading: true };
    case SMS_MULTI_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case SMS_MULTI_SEND_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
