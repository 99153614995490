import axios from 'axios';

import {
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_ADD_REQUEST,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ADD_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_LIST_REMOVE_ITEM,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_DETAILS_FAIL,
  DEPARTMENT_EDIT_REQUEST,
  DEPARTMENT_EDIT_SUCCESS,
  DEPARTMENT_EDIT_FAIL,
} from '../constants/departmentConstants';
import { setAlert } from './alertActions';

// Get all batch
export const listDepartments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/departments`, config);

    dispatch({
      type: DEPARTMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get single department details
export const getDepartmentDetails = (dept_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/departments/${dept_id}`, config);

    dispatch({
      type: DEPARTMENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Edit single department details
export const editDepartment =
  (dept_id, values) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEPARTMENT_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/departments/${dept_id}`,
        values,
        config
      );

      dispatch({
        type: DEPARTMENT_EDIT_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Department edited', 'success'));
    } catch (error) {
      dispatch(setAlert(error.response.data, 'error'));
      dispatch({
        type: DEPARTMENT_EDIT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Add new department
export const addDepartment = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/departments`, values, config);

    dispatch({
      type: DEPARTMENT_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Department added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: DEPARTMENT_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete a department
export const deleteDepartment = (dept_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/departments/${dept_id}`);

    dispatch({
      type: DEPARTMENT_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: DEPARTMENT_LIST_REMOVE_ITEM,
      payload: dept_id,
    });
    dispatch(setAlert('Department deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: DEPARTMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
