import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../../components/Message';
import Meta from '../../components/Meta';
import { addFaculty, listFaculties } from '../../actions/facultyActions';

const FacultyAddScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    fac_name: '',
  });

  const facultyAdd = useSelector((state) => state.facultyAdd);
  const { loading, success, error, department } = facultyAdd;

  const facultyList = useSelector((state) => state.facultyList);
  const {
    loading: loadingFaculties,
    error: errorFaculties,
    faculties,
  } = facultyList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/faculties');
      }, 2000);
    } else {
      if (!faculties) {
        dispatch(listFaculties());
      }
    }
  }, [history, dispatch, success, faculties]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addFaculty(values));
  };

  return (
    <Container>
      <Meta
        title={'Add Faculty'}
        description={
          'Add new faculty to the database of Notre Dame University Bangladesh'
        }
        keywords={'add new faculty, faculty'}
      />
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Add new faculty</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="text"
              value={values.fac_name}
              onChange={handleChange('fac_name')}
              label="Faculty Name"
              style={{ width: '100%' }}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Link to={'/faculties'}>
              <Button variant="outlined" disableElevation type="submit">
                Back
              </Button>
            </Link>
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: '10px' }}
              onClick={(e) => onSubmit(e)}
            >
              Add{' '}
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default FacultyAddScreen;
