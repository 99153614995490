import React from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { dictionary } from './CsvDictionary';
import { Button } from '@mui/material';

const CsvDownloader = ({ data, columns, filename }) => {
  const mapped = data.map((x) => _.pick(x, columns));

  const csvData = [];
  csvData.push(columns.map((x) => dictionary[x]));

  // Format the properties of request body that are non-existent  to an array for insertion
  mapped.forEach((x) => {
    let newArray = [];
    columns.forEach((y) => {
      newArray.push(x[y]);
    });
    csvData.push(newArray);
  });

  return (
    <CSVLink data={csvData} filename={filename}>
      <Button size="medium" style={{ marginTop: '1px' }}>
        Download
      </Button>
    </CSVLink>
  );
};

export default CsvDownloader;
