import axios from 'axios';

import {
  TEACHER_LIST_REQUEST,
  TEACHER_LIST_SUCCESS,
  TEACHER_LIST_FAIL,
  TEACHER_ADD_REQUEST,
  TEACHER_ADD_SUCCESS,
  TEACHER_ADD_FAIL,
  TEACHER_DELETE_REQUEST,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAIL,
  TEACHER_LIST_REMOVE_ITEM,
  TEACHER_DETAILS_REQUEST,
  TEACHER_DETAILS_SUCCESS,
  TEACHER_DETAILS_FAIL,
  TEACHER_EDIT_REQUEST,
  TEACHER_EDIT_SUCCESS,
  TEACHER_EDIT_FAIL,
} from '../constants/teacherConstants';
import { setAlert } from './alertActions';

// Get all batch
export const listTeachers =
  (
    dept_id = '',
    teacher_name = '',
    teacher_email = '',
    teacher_designation = '',
    sortBy = '',
    sortingOrder = '',
    pageNumber = 1,
    pageSize = 50000
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: TEACHER_LIST_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `/api/teachers?teacher_name=${teacher_name}&teacher_email=${teacher_email}&teacher_designation=${teacher_designation}&dept_id=${dept_id}&sortBy=${sortBy}&sortingOrder=${sortingOrder}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: TEACHER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEACHER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Get single teacher details
export const getTeacherDetails = (teacher_id) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_DETAILS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`/api/teachers/${teacher_id}`, config);

    dispatch({
      type: TEACHER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEACHER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Edit single teacher details
export const editTeacher = (dept_id, values) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_EDIT_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(
      `/api/teachers/${dept_id}`,
      values,
      config
    );

    dispatch({
      type: TEACHER_EDIT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Teacher edited', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: TEACHER_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add new teacher
export const addTeacher = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_ADD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`/api/teachers`, values, config);

    dispatch({
      type: TEACHER_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Teacher added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: TEACHER_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Delete a teacher
export const deleteTeacher = (dept_id) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/teachers/${dept_id}`);

    dispatch({
      type: TEACHER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: TEACHER_LIST_REMOVE_ITEM,
      payload: dept_id,
    });
    dispatch(setAlert('Teacher deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: TEACHER_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
