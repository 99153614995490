import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NDUB_LOGO from '../../img/NDUB_Logo.png';

import Message from '../../components/Message';
import { useEffect } from 'react';
import { setAlert } from '../../actions/alertActions';
import { resetPassword } from '../../actions/userActions';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordResetScreen = ({ history }) => {
  const dispatch = useDispatch();

  let query = useQuery();

  console.log(query.get('token'));

  const [token, setToken] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // console.log(location.search);

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const userPasswordReset = useSelector((state) => state.userPasswordReset);
  const { loading, success } = userPasswordReset;

  useEffect(() => {
    if (!query.get('token')) {
      history.push('/login');
    } else {
      setToken(query.get('token'));
    }
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/login');
      }, 8000);
    }
  }, [success]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      dispatch(setAlert('Passwords do not match', 'error'));
    } else {
      dispatch(resetPassword(token, password, confirmPassword));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Grid
        container
        className="login-container"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="login-art-container"
          direction={'column'}
        >
          <img src={NDUB_LOGO} alt="NDUB Logo" className="login-art" />
          <h2 className="primary-header">Set New Password</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="grid-center">
          <Card variant="outlined" className="login-form-container">
            <h2 className="primary-header mb-20">Password</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <TextField
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
              />
              <TextField
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Confirm Password"
              />

              <i className="fas fa-user" style={{ color: '#000' }} />
              <Button
                variant="contained"
                disableElevation
                size="large"
                style={{ width: '100%', marginBottom: '20px' }}
                type="submit"
              >
                Submit
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
            <Typography variant="p">
              Already have an account?{' '}
              <Link to="/login" style={{ textDecoration: 'underline' }}>
                Login
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default PasswordResetScreen;
