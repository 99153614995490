export const DEPARTMENT_LIST_REQUEST = 'DEPARTMENT_LIST_REQUEST';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL';
export const DEPARTMENT_LIST_RESET = 'DEPARTMENT_LIST_RESET';
export const DEPARTMENT_LIST_REMOVE_ITEM = 'DEPARTMENT_LIST_REMOVE_ITEM';

export const DEPARTMENT_DETAILS_REQUEST = 'DEPARTMENT_DETAILS_REQUEST';
export const DEPARTMENT_DETAILS_SUCCESS = 'DEPARTMENT_DETAILS_SUCCESS';
export const DEPARTMENT_DETAILS_FAIL = 'DEPARTMENT_DETAILS_FAIL';
export const DEPARTMENT_DETAILS_RESET = 'DEPARTMENT_DETAILS_RESET';

export const DEPARTMENT_EDIT_REQUEST = 'DEPARTMENT_EDIT_REQUEST';
export const DEPARTMENT_EDIT_SUCCESS = 'DEPARTMENT_EDIT_SUCCESS';
export const DEPARTMENT_EDIT_FAIL = 'DEPARTMENT_EDIT_FAIL';
export const DEPARTMENT_EDIT_RESET = 'DEPARTMENT_EDIT_RESET';

export const DEPARTMENT_ADD_REQUEST = 'DEPARTMENT_ADD_REQUEST';
export const DEPARTMENT_ADD_SUCCESS = 'DEPARTMENT_ADD_SUCCESS';
export const DEPARTMENT_ADD_FAIL = 'DEPARTMENT_ADD_FAIL';
export const DEPARTMENT_ADD_RESET = 'DEPARTMENT_ADD_RESET';

export const DEPARTMENT_DELETE_REQUEST = 'DEPARTMENT_DELETE_REQUEST';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAIL = 'DEPARTMENT_DELETE_FAIL';
export const DEPARTMENT_DELETE_RESET = 'DEPARTMENT_DELETE_RESET';
