import axios from 'axios';

import {
  FACULTY_LIST_REQUEST,
  FACULTY_LIST_SUCCESS,
  FACULTY_LIST_FAIL,
  FACULTY_DETAILS_REQUEST,
  FACULTY_DETAILS_SUCCESS,
  FACULTY_DETAILS_FAIL,
  FACULTY_ADD_REQUEST,
  FACULTY_ADD_SUCCESS,
  FACULTY_ADD_FAIL,
  FACULTY_EDIT_REQUEST,
  FACULTY_EDIT_SUCCESS,
  FACULTY_EDIT_FAIL,
  FACULTY_DELETE_REQUEST,
  FACULTY_DELETE_SUCCESS,
  FACULTY_LIST_REMOVE_ITEM,
  FACULTY_DELETE_FAIL,
} from '../constants/facultyConstants';
import { setAlert } from './alertActions';

// Get all batch
export const listFaculties = () => async (dispatch) => {
  try {
    dispatch({ type: FACULTY_LIST_REQUEST });

    const { data } = await axios.get(`/api/faculties`);

    dispatch({
      type: FACULTY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: FACULTY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get single faculty details
export const getFacultyDetails = (fac_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FACULTY_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/faculties/${fac_id}`, config);

    dispatch({
      type: FACULTY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: FACULTY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Add new department
export const addFaculty = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: FACULTY_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/faculties`, values, config);

    dispatch({
      type: FACULTY_ADD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Faculty added', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: FACULTY_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Edit single faculty details
export const editFaculty = (fac_id, values) => async (dispatch, getState) => {
  try {
    dispatch({ type: FACULTY_EDIT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/faculties/${fac_id}`,
      values,
      config
    );

    dispatch({
      type: FACULTY_EDIT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Faculty edited', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: FACULTY_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete a faculty
export const deleteFaculty = (fac_id) => async (dispatch) => {
  try {
    dispatch({ type: FACULTY_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/faculties/${fac_id}`);

    dispatch({
      type: FACULTY_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: FACULTY_LIST_REMOVE_ITEM,
      payload: fac_id,
    });
    dispatch(setAlert('Faculty deleted', 'success'));
  } catch (error) {
    dispatch(setAlert(error.response.data, 'error'));
    dispatch({
      type: FACULTY_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
