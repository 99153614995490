import {
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Autocomplete,
  Pagination,
  FormControl,
  Select,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourse, listCourses } from '../../actions/courseActions';
import { listEvaluationCourses } from '../../actions/evaluationActions';
import { listPrograms } from '../../actions/programActions';
import { listBatches } from '../../actions/batchActions';
import { listTeachers } from '../../actions/teacherActions';
import { Link } from 'react-router-dom';
import StyledTableCell from '../../components/StyledTableCell';
import Message from '../../components/Message';
import { listSemesters } from '../../actions/semesterActions';
import { REMOVE_ALERT } from '../../constants/alertConstants';
import ProgramSearchableDropdown from '../../components/dropdowns/ProgramSearchableDropdown';
import BatchSearchableDropdown from '../../components/dropdowns/BatchSearchableDropdown';
import SemesterSearchableDropdown from '../../components/dropdowns/SemesterSearchableDropdown';
import CourseSearchableDropdown from '../../components/dropdowns/CourseSearchableDropdown';
import TeacherSearchableDropdown from '../../components/dropdowns/TeacherSearchableDropdown';
import { v4 as uuid } from 'uuid';
import {
  EVALUATION_COURSE_DETAILS_RESET,
  EVALUATION_OVERALL_RESULT_RESET,
  EVALUATION_PER_QUES_RESULT_RESET,
} from '../../constants/evaluationConstants';

const EvaluationAdminScreen = () => {
  const dispatch = useDispatch();

  const [batch, setBatch] = useState(null);
  const [semester, setSemester] = useState(null);
  const [program, setProgram] = useState(null);
  const [course, setCourse] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [sortBy, setSortBy] = useState('sem_id');
  const [sortingOrder, setSortingOrder] = useState('DESC');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pagesFromAPI, setPagesFromAPI] = useState(null);

  const evaluationCourseList = useSelector(
    (state) => state.evaluationCourseList
  );
  const { loading, evaluationCourses, page, pages, total } =
    evaluationCourseList;

  const batchList = useSelector((state) => state.batchList);
  const { loading: loadingBatches, batches } = batchList;

  const semesterList = useSelector((state) => state.semesterList);
  const { semesters, loading: loadingSemesters } = semesterList;

  const teacherList = useSelector((state) => state.teacherList);
  const { teachers } = teacherList;

  const programList = useSelector((state) => state.programList);
  const { programs } = programList;

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }

    dispatch({ type: EVALUATION_COURSE_DETAILS_RESET });
    dispatch({ type: EVALUATION_OVERALL_RESULT_RESET });
    dispatch({ type: EVALUATION_PER_QUES_RESULT_RESET });

    // Fetch the courses on component mount
    if (!evaluationCourses) {
      dispatch(
        listEvaluationCourses(
          semester?.sem_id,
          program?.program_id,
          course?.course_id,
          batch?.batch_id,
          teacher?.teacher_id,
          sortBy,
          sortingOrder,
          pageNumber,
          pageSize
        )
      );
    }

    // Fetch the batches and programs and teachers on component mount
    if (!batches) {
      dispatch(listBatches());
    }
    if (!programs) {
      dispatch(listPrograms());
    }
    if (!courses) {
      dispatch(listCourses());
    }
    if (!teachers) {
      dispatch(listTeachers());
    }
    if (!semesters) {
      dispatch(listSemesters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingSemesters && semesters && semesters.length > 0) {
      setSemester(
        semesters?.filter((sem) => {
          if (sem.evaluation_open) return sem;
        })[0]
      );
    }
  }, [semesters]);

  // Fetch the courses on component filter value update
  useEffect(() => {
    dispatch(
      listEvaluationCourses(
        semester?.sem_id,
        program?.program_id,
        course?.course_id,
        batch?.batch_id,
        teacher?.teacher_id,
        sortBy,
        sortingOrder,
        pageNumber,
        pageSize
      )
    );
  }, [
    dispatch,
    batch,
    semester,
    program,
    course,
    teacher,
    sortBy,
    sortingOrder,
    pageNumber,
    pageSize,
    pagesFromAPI,
  ]);

  const handleProgramChange = (program) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    // Fetch the programs on department change
    dispatch(listBatches(program?.program_id));
    dispatch(listCourses(program?.program_id));
    dispatch(listTeachers(program?.dept_id));
    setProgram(program);
  };

  const handleBatchChange = (batch) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setBatch(batch);
  };

  const handleSemesterChange = (semester) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setSemester(semester);
  };

  const handleCourseChange = (course) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setCourse(course);
  };

  const handleTeacherChange = (teacher) => {
    setPageNumber(1);
    setPagesFromAPI(null);
    setTeacher(teacher);
  };

  const handleDelete = (course_id) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      // dispatch(deleteCourse(course_id));
    }
  };

  const handlePageChange = (event, value) => {
    setPagesFromAPI(pages);
    setPageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    setPageNumber(1);
    setPageSize(event.target.value);
  };

  return (
    <Container>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} sm={4}>
          <SemesterSearchableDropdown
            semester={semester}
            setSemester={handleSemesterChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ProgramSearchableDropdown
            program={program}
            setProgram={handleProgramChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CourseSearchableDropdown
            course={course}
            setCourse={handleCourseChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BatchSearchableDropdown batch={batch} setBatch={handleBatchChange} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TeacherSearchableDropdown
            teacher={teacher}
            setTeacher={handleTeacherChange}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={sortBy}
            label="Sort By"
            onChange={(e) => setSortBy(e.target.value)}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="sem_id">Semester</MenuItem>
            <MenuItem value="program_id">Program</MenuItem>
            <MenuItem value="course_id">Course</MenuItem>
            <MenuItem value="batch_id">Batch</MenuItem>
            <MenuItem value="teacher_id">Teacher</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            value={sortingOrder}
            label="Order"
            onChange={(e) => setSortingOrder(e.target.value)}
            style={{ width: '100%' }}
            size="small"
          >
            <MenuItem value="ASC">Ascending</MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // alignContent: 'center',
              // justifyContent: 'center',
            }}
          >
            <Pagination
              disabled={loading}
              count={pages ? pages : pagesFromAPI}
              page={pageNumber}
              boundaryCount={2}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
            <p style={{ marginLeft: '20px' }}>Page Size: &nbsp;</p>
            <FormControl size="small">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
            {total ? (
              <small style={{ marginLeft: '20px' }}>
                {total} records found
              </small>
            ) : (
              <small style={{ marginLeft: '20px' }}>No records found</small>
            )}
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="course list table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell>Course Name</StyledTableCell>
              <StyledTableCell align="left">Teacher</StyledTableCell>
              <StyledTableCell align="left">Code</StyledTableCell>
              <StyledTableCell align="left">Semester</StyledTableCell>
              <StyledTableCell align="left">Batch</StyledTableCell>
              <StyledTableCell align="left">Program</StyledTableCell>
              {/* <StyledTableCell align="center">Delete</StyledTableCell> */}
              <StyledTableCell align="center">Evaluation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationCourses?.map((course, index) => (
              <TableRow
                key={uuid()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {course.course_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {course.teacher_name}
                </TableCell>
                <TableCell align="left">{course.course_code}</TableCell>
                <TableCell align="left">
                  {course.sem_name} {course.sem_year}
                </TableCell>
                <TableCell align="left">{course.batch_name}</TableCell>
                <TableCell align="left">{course.program_code}</TableCell>
                {/* <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(course.course_id)}
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell> */}
                <TableCell align="center">
                  <Link
                    to={`/evaluation/teacher?sem_id=${course.sem_id}&course_id=${course.course_id}&teacher_id=${course.teacher_id}&batch_id=${course.batch_id}`}
                  >
                    <IconButton size="small">
                      <ArrowForwardIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}

            {!loading && !evaluationCourses && (
              <TableRow>
                <TableCell>No results found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EvaluationAdminScreen;
