import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_LIST_RESET,
  COURSE_LIST_REMOVE_ITEM,
  COURSE_ADD_SUCCESS,
  COURSE_ADD_FAIL,
  COURSE_ADD_REQUEST,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
  COURSE_DELETE_RESET,
  COURSE_EDIT_REQUEST,
  COURSE_EDIT_SUCCESS,
  COURSE_EDIT_FAIL,
  COURSE_EDIT_RESET,
  COURSE_ADD_RESET,
  COURSE_ADD_BULK_REQUEST,
  COURSE_ADD_BULK_SUCCESS,
  COURSE_ADD_BULK_FAIL,
  COURSE_ADD_BULK_RESET,
} from '../constants/courseConstants';

export const courseListReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return { loading: true };
    case COURSE_LIST_SUCCESS:
      return {
        loading: false,
        courses: action.payload.courses,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      };
    case COURSE_LIST_REMOVE_ITEM:
      return {
        ...state,
        courses: state.courses.filter(
          (course) => course.course_id !== action.payload
        ),
      };
    case COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const courseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DETAILS_REQUEST:
      return { loading: true };
    case COURSE_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        course: action.payload,
      };
    case COURSE_DETAILS_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const courseAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ADD_REQUEST:
      return { loading: true };
    case COURSE_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        course: action.payload,
      };
    case COURSE_ADD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case COURSE_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const courseAddBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_ADD_BULK_REQUEST:
      return { loading: true };
    case COURSE_ADD_BULK_SUCCESS:
      return {
        loading: false,
        success: true,
        course: action.payload,
      };
    case COURSE_ADD_BULK_FAIL:
      return { loading: false, success: false, error: action.payload };
    case COURSE_ADD_BULK_RESET:
      return {};
    default:
      return state;
  }
};

export const courseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_REQUEST:
      return { loading: true };
    case COURSE_DELETE_SUCCESS:
      return {
        loading: false,
        course: action.payload,
      };
    case COURSE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const courseEditReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_EDIT_REQUEST:
      return { loading: true };
    case COURSE_EDIT_SUCCESS:
      return {
        loading: false,
        course: action.payload,
        success: true,
      };
    case COURSE_EDIT_FAIL:
      return { loading: false, error: action.payload, success: false };
    case COURSE_EDIT_RESET:
      return {};
    default:
      return state;
  }
};
